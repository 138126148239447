import { create } from 'zustand';

type FeatureFlagsState = {
  isEditoriallyControlledRibbonEnabled: boolean;
};

type FeatureFlagsActions = {
  updateFlag: (flag: keyof FeatureFlagsState, value: boolean) => void;
};

export const [useFeatureFlags] = create<
  FeatureFlagsState & FeatureFlagsActions
>(set => ({
  isEditoriallyControlledRibbonEnabled: false,
  updateFlag: (flag, value) => set(prev => ({ ...prev, [flag]: value }))
}));

// Fetch flags configuration
const featureFlagsConfig = {
  isEditoriallyControlledRibbonEnabled: {
    campaignKey:
      process.env.NEXT_PUBLIC_VWO_EDITORIALLY_RIBBON_FLAG_CAMPAIGN_KEY,
    sdkKey: process.env.NEXT_PUBLIC_VWO_EDITORIALLY_RIBBON_KEY,
    loggerName: 'Editorial Section VWO Query'
  }
};

export { featureFlagsConfig };
export type { FeatureFlagsActions, FeatureFlagsState };
