import { throttle } from 'lodash-es';

import mixpanel from 'lib/analytics/mixpanel';
import { VideoPlayerStore } from '../store';
import {
  UniversalPropertiesOverride,
  TalkMarks,
  PlayerMarks
} from '../analytics/mixpanel.types';

// Tallies used for complex events
const playerMarks: PlayerMarks = {
  hasPlayedSuccessfully: false,
  hasInitiallyRequested: false,
  hasUnfulfilledRequest: false
};

export const talkMarks: TalkMarks = {};

export const universalProperties = (
  state: VideoPlayerStore,
  payload?: UniversalPropertiesOverride
) => {
  const {
    service,
    subtitle,
    playbackRate,
    streamHost,
    autoplayed,
    mixpanelTalkDetails: {
      content_discovery_context,
      talk_id,
      talk_slug,
      video_duration,
      video_topics,
      video_post_date,
      talkstar_context,
      talk_language,
      system_language,
      product,
      talk_type,
      playlist_slug
    }
  } = state;

  return {
    service,
    talk_id,
    talk_slug,
    video_duration,
    video_topics,
    video_post_date,
    talkstar_context,
    talk_language,
    system_language,
    product,
    talk_type,
    subtitle_language: subtitle,
    playlist_slug,
    playback_rate: payload?.playbackRate || playbackRate,
    stream_host: streamHost,
    content_discovery_context,
    autoplay_state: autoplayed
  };
};

// Reset player tallies
function resetPlayerMarks(): void {
  delete playerMarks.hasPlayedSuccessfully;
  delete playerMarks.hasInitiallyRequested;
  delete playerMarks.hasUnfulfilledRequest;
}

// Reset talk tallies
function resetTalkMarks(): void {
  delete talkMarks.talkId;
  delete talkMarks.currentTime;
  delete talkMarks.duration;
  delete talkMarks.hasCompleted;

  // Un-emitted progress pct markers at 25%, 50%, 75%, 95%
  talkMarks.remainderPct = [25, 50, 75, 95];

  // Un-emitted progress time markers at 10s, 30s
  talkMarks.remainderTime = [10, 30];
}

const getProgress = throttle(
  (currentTime, duration) => {
    if (!talkMarks.is95PercentComplete)
      return Math.floor((currentTime / duration) * 100);
  },
  1000,
  { trailing: false }
);

export function sendProgressTime(state: VideoPlayerStore): void {
  const progressMarks = [25, 50, 75, 95];
  const talkMarkKeys = [
    'is25PercentComplete',
    'is50PercentComplete',
    'is75PercentComplete',
    'is95PercentComplete'
  ];

  const progress = getProgress(talkMarks.currentTime, state.duration);

  progressMarks.forEach((mark, index) => {
    const key = talkMarkKeys[index];
    if (progress === mark && !talkMarks[key]) {
      mixpanel.track(`player_${progress}_progress_time`, {
        ...universalProperties(state),
        recommendation_id: state?.mixpanelTalkDetails?.recommendation_id
      });
      talkMarks[key] = true;
    }
  });
}

function sendRequest(): void {
  playerMarks.hasInitiallyRequested = true;
}

// Control whether this middleware is active.
// Normally set during hydration based on the media source:
// this middleware only applies to internally sourced media.
// Also set manually for unit tests.
let isActive = false;
export function setIsActive(value: boolean): void {
  isActive = value;
}

export const middleware = () => store => (set, get, api) =>
  store(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (getPayload: any, name?: string) => {
      const payload = getPayload();

      switch (name) {
        case 'onService':
          setIsActive(payload?.service === 'youtube');
          break;

        case 'onTalk':
          resetTalkMarks();
          if (payload) talkMarks.talkId = payload?.currentTalk;
          break;

        default:
          break;
      }

      if (!isActive) return set(getPayload, name);
      const state = get();

      switch (name) {
        case 'reset':
          break;

        case 'requestPlay':
          sendRequest();
          playerMarks.hasUnfulfilledRequest = true;
          break;

        case 'onReady': {
          resetPlayerMarks();
          break;
        }

        case 'onPlay':
          if (!playerMarks.hasInitiallyRequested) {
            mixpanel.track('player_start', {
              ...universalProperties(state),
              transcriptLanguage:
                state?.transcriptLanguage ||
                state?.mixpanelTalkDetails?.transcript_language,
              recommendation_id: state?.mixpanelTalkDetails?.recommendation_id,
              language_parameter_value:
                state?.mixpanelTalkDetails?.language_parameter_value
            });

            playerMarks.hasInitiallyRequested = true;
          }

          if (playerMarks.hasUnfulfilledRequest) {
            delete playerMarks.hasUnfulfilledRequest;
          } else {
            sendRequest();
          }

          if (playerMarks.hasPlayedSuccessfully) {
            mixpanel.track('player_start', {
              ...universalProperties(state),
              transcriptLanguage:
                state?.transcriptLanguage ||
                state?.mixpanelTalkDetails?.transcript_language,
              recommendation_id: state?.mixpanelTalkDetails?.recommendation_id,
              language_parameter_value:
                state?.mixpanelTalkDetails?.language_parameter_value
            });

            playerMarks.hasPlayedSuccessfully = true;
          }
          break;

        case 'onPause':
          mixpanel.track('player_paused', {
            ...universalProperties(state),
            action_time: payload?.roundedTime,
            recommendation_id: state?.mixpanelTalkDetails?.recommendation_id,
            media_type: state?.mixpanelTalkDetails?.media_type
          });

          break;

        case 'onDuration':
          talkMarks.duration = payload.duration;
          break;

        case 'onCurrentTime':
          talkMarks.currentTime = payload.time;
          sendProgressTime(state);
          break;

        case 'onPlaybackMode':
          if (!talkMarks.hasCompleted) {
            mixpanel.track('player_complete', {
              ...universalProperties(state),
              recommendation_id: state?.mixpanelTalkDetails?.recommendation_id
            });

            talkMarks.hasCompleted = true;
          }
          break;

        default:
          break;
      }

      return set(getPayload, name);
    },
    get,
    api
  );

export function clearMarks(): void {
  resetPlayerMarks();
  resetTalkMarks();
}

clearMarks();

export default middleware;
