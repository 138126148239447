import pino, { Logger } from 'pino';

// Store logger instances by label
const loggerInstances: Record<string, Logger> = {};

const getConfig = (label: string) => {
  // Check if we're in a browser environment
  const isBrowser = typeof window !== 'undefined';
  // Check if we're in a server environment
  const isServer = !isBrowser && typeof process !== 'undefined';
  // More specifically, check if we're in Next.js SSR
  const isNextJs =
    isServer &&
    (process.env.NEXT_RUNTIME === 'nodejs' ||
      // eslint-disable-next-line no-underscore-dangle
      process.env.__NEXT_PROCESSED_ENV ||
      process.env.NEXT_PUBLIC_VERCEL_ENV);

  const baseConfig = {
    name: label
  };

  switch (process.env.NODE_ENV) {
    case 'development':
      if (isBrowser) {
        // Browser-specific config
        return {
          ...baseConfig,
          level: 'trace',
          browser: {
            asObject: true
          }
        };
      } else if (isNextJs) {
        // Simple config for Next.js SSR without transports
        return {
          ...baseConfig,
          level: 'trace'
        };
      } else {
        // Regular Node.js server config
        return {
          ...baseConfig,
          level: 'trace',
          transport: {
            target: 'pino-pretty',
            options: {
              colorize: true,
              ignore: 'pid,hostname,name',
              messageFormat: '{levelLabel} - {pid} - url:{req.url}'
            }
          }
        };
      }
    case 'production':
      // Simple config for production that works in all environments
      return {
        ...baseConfig,
        level: 'silent'
      };
    case 'test':
      // Simple config for test that works in all environments
      return {
        ...baseConfig,
        level: 'info'
      };
    default:
      throw new Error('Invalid NODE_ENV');
  }
};

export function createLogger(label: string): Logger {
  if (!loggerInstances[label]) {
    const config = getConfig(label);
    loggerInstances[label] = pino(config);
  }
  return loggerInstances[label];
}

interface ErrorMetadata {
  errorType?: string;
  serviceName?: string;
  message?: string;
  [key: string]: unknown;
}

export function sendErrorToServices(
  message: string,
  error: unknown,
  metadata: ErrorMetadata = {}
): void {
  const log = createLogger('ErrorBoundary');

  // Extract error message if it's an Error object
  const errorMessage = error instanceof Error ? error.message : String(error);

  // Combine metadata
  const combinedMetadata = {
    ...metadata,
    message: metadata.message || errorMessage,
    errorType:
      metadata.errorType ||
      (error instanceof Error ? error.constructor.name : 'UNKNOWN')
  };

  // Log to Pino
  log.error({ error, ...combinedMetadata }, message);
}
