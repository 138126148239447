import { AddWatchedTalkDocument } from 'api';
import { initializeApollo } from 'lib/graphql/client';
import { sendErrorToServices } from 'lib/logging';

const apolloClient = initializeApollo();

export const addWatchedTalk = async (
  talkId: string,
  loggedIn: boolean,
  autoplayed?: boolean,
  secondsPlayed = 0
): Promise<Error | void> => {
  try {
    if (loggedIn) {
      await apolloClient.mutate({
        mutation: AddWatchedTalkDocument,
        variables: {
          talkInput: {
            talkId,
            secondsPlayed,
            autoplayed
          }
        }
      });
    }
  } catch (error) {
    sendErrorToServices('Error adding watched talk', error);
  }
};

export default addWatchedTalk;
