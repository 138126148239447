import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  from
} from '@apollo/client';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { isEqual, merge } from 'lodash-es';
import { useMemo } from 'react';

import { client as isBrowser } from 'f';
import { sendErrorToServices } from 'lib/logging';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject>;

if (process.env.NODE_ENV !== 'production') {
  loadDevMessages();
  loadErrorMessages();
}

const HttpCustomHeaders = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'Client-ID': `Zenith ${process.env.NODE_ENV}`,
      'X-Operation-Name': operation.operationName
    }
  }));

  return forward(operation);
});

const responseLogger = new ApolloLink((operation, forward) => {
  return forward(operation).map(data => {
    return data;
  });
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const { variables, operationName } = operation;
  const { response } = operation.getContext();

  const errorObj = {
    operationName,
    response,
    variables
  };

  if (graphQLErrors)
    graphQLErrors.map(({ message }) =>
      sendErrorToServices(
        message,
        `[GraphQL error]: Message: ${message}, Operation: ${operationName}, Variables: ${JSON.stringify(
          variables
        )}`
      )
    );

  if (networkError) {
    sendErrorToServices('[Network error]:', {
      networkError,
      ...errorObj
    });
  }
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    jitter: true
  },
  attempts: {
    max: 3,
    retryIf: error => !!error
  }
});

const httpLink = new HttpLink({
  uri: `${isBrowser ? window.location.origin : process.env.API_URL}/graphql`,
  credentials: 'include'
});

const cache = new InMemoryCache({
  typePolicies: {
    Video: {
      fields: {
        talkExtras: {
          merge(existing, incoming) {
            if (!existing) return incoming;

            // Merge the existing and incoming objects, preserving all fields
            return {
              ...existing,
              ...incoming
            };
          }
        }
      }
    }
  }
});

function createApolloClient() {
  return new ApolloClient({
    ssrMode: !isBrowser,
    link: from([
      HttpCustomHeaders,
      errorLink,
      responseLogger,
      retryLink,
      httpLink
    ]),
    ssrForceFetchDelay: 1000,
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore'
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      },
      mutate: {
        errorPolicy: 'all'
      }
    }
  });
}

export function initializeApollo(initialState = null): typeof apolloClient {
  const client = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = client.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s)))
      ]
    });

    // Restore the cache with the merged data
    client.cache.restore(data);
  }

  // For SSG and SSR always create a new Apollo Client
  if (!isBrowser) return client;

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = client;

  return client;
}

export function useApollo(
  pageProps: unknown
): ApolloClient<NormalizedCacheObject> {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
