import { createLogger } from 'lib/logging';
import { useEffect, useRef } from 'react';

const logger = createLogger('GPTScript');

/**
 * Initializes the googletag object if it doesn't exist.
 */
function initializeGPT(): void {
  if (typeof window === 'undefined') return;

  if (!window.googletag) {
    // Initialize as an empty object and then add properties
    window.googletag = {} as typeof window.googletag;
    window.googletag.cmd = [];
  }
}

/**
 * GPTScript sets up GPT configuration.
 * All window-dependent logic is deferred until after mounting to avoid SSR/hydration issues.
 */
export function GPTScript(): JSX.Element | null {
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initializeGPT();
      initialized.current = true;
    }

    window.googletag?.cmd?.push(() => {
      const pubads = window.googletag?.pubads?.();
      if (pubads) {
        pubads.enableVideoAds();
        window.googletag?.enableServices?.();
      }
    });
  }, []);

  return null;
}

/**
 * Returns true if GPT is loaded and its API is ready.
 */
export function isGPTReady(): boolean {
  if (typeof window === 'undefined') return false;
  return Boolean(window.googletag?.apiReady);
}

/**
 * Executes a GPT command safely by pushing it onto the GPT command queue.
 */
export function executeGPTCommand(command: () => void): void {
  initializeGPT();

  window.googletag?.cmd?.push(() => {
    try {
      command();
    } catch (error) {
      logger.error('Error executing GPT command:', error);
    }
  });
}
