import { createLogger } from 'lib/logging';
import { generateSig } from '../hooks/useSailthru/utils';

const log = createLogger('sailthru-client');

export class SailthruError extends Error {
  constructor(
    message: string,
    public readonly statusCode?: number
  ) {
    super(message);
    this.name = 'SailthruError';
  }
}

export default async function getSailthruVars(userObject = {}) {
  try {
    const json = JSON.stringify(userObject);
    const sig = generateSig(json);

    const apiKey = process.env.NEXT_PUBLIC_SAILTHRU_API_KEY;
    if (!apiKey) {
      throw new SailthruError('Missing Sailthru API key');
    }

    const params = new URLSearchParams();
    params.append('api_key', apiKey);
    params.append('format', 'json');
    params.append('json', json);
    params.append('sig', sig);

    const response = await fetch(`/api/sailthru/get-profile-vars?${params}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new SailthruError(
        `Failed to fetch sailthru vars: ${errorText}`,
        response.status
      );
    }

    const data = await response.json();

    if (!data) {
      throw new SailthruError('Empty response from Sailthru');
    }

    return data;
  } catch (error) {
    if (error instanceof SailthruError) {
      // If it's an authentication error, log it but don't retry
      if (error.statusCode === 401 || error.statusCode === 403) {
        log.error('Sailthru authentication error:', error);
        return null;
      }
    }

    // Log the error with context
    log.error('Error fetching Sailthru vars:', {
      error,
      userObject: JSON.stringify(userObject)
    });

    // Return null to indicate failure but allow the app to continue
    return null;
  }
}
