import type { LinkResolverFunction } from '@prismicio/client';
import * as prismic from '@prismicio/client';
import { CreateClientConfig, enableAutoPreviews } from '@prismicio/next';
import config from '../slicemachine.config.json';

/**
 * The project's Prismic repository name.
 */
export const repositoryName =
  process.env.NEXT_PUBLIC_PRISMIC_ENVIRONMENT || config.repositoryName;

/**
 * A list of Route Resolver objects that define how a document's `url` field is resolved.
 *
 * {@link https://prismic.io/docs/route-resolver#route-resolver}
 */
// TODO: Update the routes array to match your project's route structure.
const routes: prismic.ClientConfig['routes'] = [
  // Examples:
  {
    type: 'page',
    path: '/pages/:uid'
  },
  {
    type: 'landingPage',
    path: '/pages/landingPage/:uid'
  }
];

/**
 * Creates a Prismic client for the project's repository. The client is used to
 * query content from the Prismic API.
 *
 * @param config - Configuration for the Prismic client.
 */
export const createClient = (config: CreateClientConfig = {}) => {
  const client = prismic.createClient(repositoryName, {
    routes,
    ...config
  });

  enableAutoPreviews({
    client,
    previewData: config.previewData,
    req: config.req
  });

  return client;
};

/**
 * Section path mapping type for strong typing of section keys
 */
type SectionPathMap = {
  podcasts: string;
  watch: string;
  read: string;
  attend: string;
  participate: string;
  [key: string]: string; // Allow for additional keys
};

/**
 * Interface for a section object in the document data
 */
interface Section {
  slug?: string;
  [key: string]: unknown;
}

/**
 * Interface for the page document data structure
 */
interface PageDocumentData {
  section?: Section;
  [key: string]: unknown;
}

/**
 * Link resolver maps Prismic documents to URLs
 * It determines the correct URL path based on document type, section, and other metadata
 *
 * @param doc - The Prismic document or link field to resolve a URL for
 */
export const linkResolver: LinkResolverFunction = doc => {
  if (!doc) return '/';

  const sectionPathMap: SectionPathMap = {
    podcasts: '/podcasts',
    watch: '/watch',
    read: '/read',
    attend: '/attend',
    participate: '/participate'
    // Add more mappings as needed
  };

  if (doc.type === 'page') {
    const data = (doc.data || {}) as PageDocumentData;
    const section = data.section || {};
    const sectionSlug = section.slug;

    // Check if the document has a section field with a slug
    if (sectionSlug && sectionPathMap[sectionSlug as keyof SectionPathMap]) {
      return `${sectionPathMap[sectionSlug as keyof SectionPathMap]}/${doc.uid}`;
    }

    if (doc.tags && Array.isArray(doc.tags)) {
      for (const tag of doc.tags) {
        // Convert tag to lowercase
        const normalizedTag = tag.toLowerCase();

        // Check if this tag matches any of our known section names
        for (const [sectionSlug, path] of Object.entries(sectionPathMap)) {
          if (normalizedTag === sectionSlug.toLowerCase()) {
            return `${path}/${doc.uid}`;
          }
        }
      }
    }

    // Default fallback for pages without specific section mapping
    return `/pages/${doc.uid}`;
  }

  return '/';
};
