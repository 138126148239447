import { useCanonicalUrl } from 'lib/hooks/useCanonicalUrl';
import Head from 'next/head';
import { useRouter } from 'next/router';
import * as React from 'react';

export default function CanonicalTags(): React.ReactNode | null {
  const canonicalUrl = useCanonicalUrl();
  const { pathname } = useRouter();

  return pathname === '/404' ? null : (
    <Head>
      <meta property="og:url" content={canonicalUrl} />
      <link rel="canonical" href={canonicalUrl} />
    </Head>
  );
}
