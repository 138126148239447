import mixpanel from 'lib/analytics/mixpanel';
import { sendErrorToServices } from 'lib/logging';
import { parseCookies } from 'nookies';
import { useEffect } from 'react';

export const useVWOCookies = (): void => {
  const getVWOCookies = (): string[] => {
    const allCookies = parseCookies();

    return Object.entries(allCookies)
      .map(([key, value]): string | null => {
        const matches = key.match(/^_vis_opt_exp_(\d+)_(\w+)$/);
        if (matches) {
          const [, testId, suffix] = matches;
          if (suffix === 'combi') {
            return `vwo_test_id_${testId}=combination=${
              value === '1' ? 'Control' : `Variation-${parseInt(value, 10) - 1}`
            }`;
          }
        }
        return null;
      })
      .filter((item): item is string => item !== null);
  };

  useEffect(() => {
    try {
      const vwoTests = getVWOCookies();
      if (mixpanel && typeof mixpanel.register === 'function') {
        mixpanel.register({ vwo_tests: vwoTests }, { persistent: false });
      }
    } catch (error) {
      sendErrorToServices('Failed to register VWO tests with Mixpanel:', error);
    }
  }, []);
};
