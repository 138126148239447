import { sendErrorToServices } from './logging';

// outputs as HH:MM:SS
const formatAsTimestamp = (duration: number): string => {
  function pad(d: number): string {
    return (d > 9 ? '' : '0') + d;
  }

  const secs = duration % 60 | 0;
  const hrs = (duration / 3600) | 0;
  const mins = ((duration % 3600) / 60) | 0;

  return `${hrs ? `${hrs}:${pad(mins)}` : pad(mins)}:${pad(secs)}`;
};

// outputs as PT1H20M or PT12M23S
const formatAsISO8601 = (duration: number): string => {
  function pad(d: number): string {
    return (d > 9 ? '' : '0') + d;
  }

  const secs = duration % 60 | 0;
  const hrs = (duration / 3600) | 0;
  const mins = ((duration % 3600) / 60) | 0;

  return `PT${hrs ? `${hrs}H${pad(mins)}M` : `${pad(mins)}M`}${pad(secs)}S`;
};

const timestampToSeconds = (timestamp: string): number => {
  const units = timestamp.split(':');
  const l = units.length;
  const secs = l > 0 ? Number(units[l - 1]) : 0;
  const minsInSecs = l > 1 ? Number(units[l - 2]) * 60 : 0;
  const hrsInSecs = l > 2 ? Number(units[l - 3]) * 60 * 60 : 0;

  return secs + minsInSecs + hrsInSecs;
};

// Firefox browser detection
declare const InstallTrigger: object | undefined;
const isFirefox = typeof InstallTrigger !== 'undefined';

function stopPropagation(
  fn: React.EventHandler<React.SyntheticEvent>
): React.EventHandler<React.SyntheticEvent> {
  return e => {
    e.stopPropagation();
    return fn(e);
  };
}

// check if device is iOS or Android, if so add 'no-hover' class name to the body element
function addNoTouchClass(): void {
  const userAgent =
    typeof window.navigator === 'undefined' ? '' : navigator.userAgent;

  const isAndroidOrIOS = Boolean(userAgent.match(/Android|iPhone|iPad|iPod/i));

  if (isAndroidOrIOS) {
    const { body } = document;
    body.classList.add('no-hover');
  }
}

function getRandomString(): string {
  // get hexadecimal number, without the starting 0.
  return Math.random().toString(16).slice(2);
}

function isValidEmail(email: string): boolean {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
}

const decodeHtmlEntities = (str: string): string =>
  str.replace(/&[a-z]+;/g, match => {
    const code = match.slice(1, -1);
    const entityMap = {
      lt: '<',
      gt: '>',
      nbsp: ' ',
      quot: `"`,
      apos: `'`,
      amp: '&'
    };

    if (code.startsWith('#')) {
      return String.fromCharCode(Number(code.slice(1)));
    }

    return entityMap[code] || match;
  });

function convertDurationToMinutes(durationString: string): string {
  const durationParts = durationString.split(':');

  // Ensure there are between two and three parts
  if (durationParts.length < 2 || durationParts.length > 3) {
    throw new Error('Invalid duration string');
  }

  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  if (durationParts.length === 2) {
    minutes = parseInt(durationParts[0], 10);
    seconds = parseInt(durationParts[1], 10);
  } else {
    hours = parseInt(durationParts[0], 10);
    minutes = parseInt(durationParts[1], 10);
    seconds = parseInt(durationParts[2], 10);
  }

  // Ensure hours, minutes, and seconds are valid numbers
  if (Number.isNaN(hours) || Number.isNaN(minutes) || Number.isNaN(seconds)) {
    throw new Error('Invalid duration string');
  }

  const totalMinutes = hours * 60 + minutes + (seconds >= 30 ? 1 : 0);

  // Return the result with "min" appended
  return `${totalMinutes} min`;
}

function truncateBySentences(str: string, maxLength: number): string {
  // Split the string into sentences.
  const sentences = str?.split('. ');

  // Check if there are enough sentences to truncate.
  if (sentences?.length <= maxLength) {
    return str;
  }

  // Truncate the sentences array to the specified length.
  const truncatedSentences = sentences?.slice(0, maxLength);

  // Join the sentences back into a string and add the ending period to the last sentence.
  return `${truncatedSentences?.join('. ')}.`;
}
function abbreviateNumber(value) {
  let newValue = value;
  const suffixes = ['', 'K', 'M', 'B', 'T'];
  let suffixNum = 0;
  while (newValue >= 1000) {
    newValue /= 1000;
    suffixNum += 1;
  }

  newValue = newValue.toPrecision(3);

  newValue += suffixes[suffixNum];
  return newValue;
}

function getSlugFromUrl(url: string): string {
  const urlObj = new URL(url);
  const { pathname } = urlObj;
  const parts = pathname.split('/');
  return parts.pop() || parts.pop(); // Handles trailing slash
}

const parseWebVTT = webvttContent => {
  const regex =
    /(\d{2}:\d{2}:\d{2}\.\d{3}) --> (\d{2}:\d{2}:\d{2}\.\d{3})\n([\s\S]*?)(?=\n\n|\n*$)/g;
  const cues = [];
  let result = regex.exec(webvttContent);

  while (result !== null) {
    cues.push({
      startTime: timestampToSeconds(result[1]),
      endTime: timestampToSeconds(result[2]),
      text: result[3].trim()
    });
    result = regex.exec(webvttContent);
  }

  return cues;
};

function isElementInView(element) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

function getHost(
  url: string | null | undefined,
  fallback: string = 'hls.ted.com'
): string {
  // Early escape for null/undefined values
  if (!url) {
    return fallback;
  }

  try {
    // First try using URL constructor for valid URLs
    const urlObj = new URL(String(url));
    return urlObj.host || fallback;
  } catch (e) {
    // Fallback to regex pattern for partial URLs
    const matches = String(url).match(/\/\/([^/]+)/);

    sendErrorToServices('Error getting stream host', e);
    return matches && matches[1] ? matches[1] : fallback;
  }
}

function removeLineBreaksAndMultiSpaces(str: string) {
  return str.replace(/ +(?= )/g, '').replace(/(\r\n|\n|\r)/gm, '');
}

export {
  abbreviateNumber,
  addNoTouchClass,
  convertDurationToMinutes,
  decodeHtmlEntities,
  formatAsISO8601,
  formatAsTimestamp,
  getHost,
  getRandomString,
  getSlugFromUrl,
  isElementInView,
  isFirefox,
  isValidEmail,
  parseWebVTT,
  removeLineBreaksAndMultiSpaces,
  stopPropagation,
  timestampToSeconds,
  truncateBySentences
};
