import { createContext, useContext, useState, type JSX } from 'react';

type DarkModeContextProps = {
  isDarkMode: boolean;
  setIsDarkMode: (value: boolean) => void;
};

type DarkmodeProviderProps = {
  children: React.ReactNode;
};

const DarkModeContext = createContext({} as DarkModeContextProps);

export function useDarkMode() {
  return useContext(DarkModeContext);
}

export const DarkModeProvider = ({
  children
}: DarkmodeProviderProps): JSX.Element => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  return (
    <DarkModeContext value={{ isDarkMode, setIsDarkMode }}>
      {children}
    </DarkModeContext>
  );
};
