export const trackVWOCustomConversion = (): void => {
  window._vis_opt_queue = window._vis_opt_queue || [];
  window._vis_opt_queue.push(() => {
    window._vis_opt_goal_conversion(200);
  });
};

export const trackVWOCustomConversionById = (id: string | number): void => {
  window._vis_opt_queue = window._vis_opt_queue || [];
  window._vis_opt_queue.push(() => {
    window._vis_opt_goal_conversion(id);
  });
};
