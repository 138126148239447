export const IS_PROD = process.env.NEXT_PUBLIC_APP_HOST === 'www.ted.com';

export const MIXPANEL_API_HOSTS = {
  PROD: 'https://mixpanel-proxy.ted.com',
  STAGING: 'https://mixpanel-proxy.staging.ted.com'
};

// Used to map legacy language codes to the new language codes
export const languageMap: { [key: string]: string } = {
  af: 'afrikaans',
  sq: 'albanian',
  arq: 'algerian arabic',
  am: 'amharic',
  ar: 'arabic',
  hy: 'armenian',
  as: 'assamese',
  ast: 'asturian',
  az: 'azerbaijani',
  eu: 'basque',
  be: 'belarusian',
  bn: 'bengali',
  bi: 'bislama',
  bs: 'bosnian',
  bg: 'bulgarian',
  my: 'burmese',
  ca: 'catalan',
  ceb: 'cebuano',
  zh: 'chinese, simplified',
  zht: 'chinese, traditional',
  yue: 'chinese, yue',
  ht: 'creole, haitian',
  hr: 'croatian',
  cs: 'czech',
  da: 'danish',
  nl: 'dutch',
  dz: 'dzongkha',
  en: 'english',
  eo: 'esperanto',
  et: 'estonian',
  fo: 'faroese',
  fil: 'filipino',
  fi: 'finnish',
  fr: 'french',
  frc: 'french (canada)',
  gl: 'galician',
  ka: 'georgian',
  de: 'german',
  el: 'greek',
  gu: 'gujarati',
  cnh: 'hakha chin',
  ha: 'hausa',
  he: 'hebrew',
  hi: 'hindi',
  hu: 'hungarian',
  hup: 'hupa',
  is: 'icelandic',
  ig: 'igbo',
  id: 'indonesian',
  inh: 'ingush',
  ga: 'irish',
  it: 'italian',
  ja: 'japanese',
  kn: 'kannada',
  kk: 'kazakh',
  km: 'khmer',
  tlh: 'klingon',
  ko: 'korean',
  ckb: 'kurdish (central)',
  ky: 'kyrgyz',
  lo: 'lao',
  ltg: 'latgalian',
  la: 'latin',
  lv: 'latvian',
  lt: 'lithuanian',
  lb: 'luxembourgish',
  mk: 'macedonian',
  mg: 'malagasy',
  ms: 'malay',
  ml: 'malayalam',
  mt: 'maltese',
  mr: 'marathi',
  mfe: 'mauritian creole',
  mn: 'mongolian',
  cnr: 'montenegrin',
  ne: 'nepali',
  kmr: 'northern kurdish (kurmanji)',
  nb: 'norwegian bokmal',
  nn: 'norwegian nynorsk',
  oc: 'occitan',
  ps: 'pashto',
  fa: 'persian',
  pl: 'polish',
  pt: 'portuguese',
  'pt-br': 'portuguese, brazilian',
  pa: 'punjabi',
  ro: 'romanian',
  ru: 'russian',
  rue: 'rusyn',
  sc: 'sardinian',
  sr: 'serbian',
  sh: 'serbo-croatian',
  szl: 'silesian',
  si: 'sinhala',
  sk: 'slovak',
  sl: 'slovenian',
  so: 'somali',
  es: 'spanish',
  sw: 'swahili',
  sv: 'swedish',
  tl: 'tagalog',
  tg: 'tajik',
  ta: 'tamil',
  tt: 'tatar',
  te: 'telugu',
  th: 'thai',
  bo: 'tibetan',
  aeb: 'tunisian arabic',
  tr: 'turkish',
  tk: 'turkmen',
  uk: 'ukrainian',
  ur: 'urdu',
  ug: 'uyghur',
  uz: 'uzbek',
  vi: 'vietnamese'
};
