// @ts-strict-ignore
import { sendBeacon, StreamingTag } from 'lib/comscore';

const sendVideoBeacon = data =>
  sendBeacon({
    c1: '1',
    c2: process.env.NEXT_PUBLIC_COMSCORE_CLIENT_ID,
    ...data
  });

const middleware = () => config => (set, get, api) => {
  let marks: {
    talkId?: string;
    isAd?: boolean;
    isPlayed?: boolean;
  } = {};

  const streamSense = new StreamingTag({
    customerC2: process.env.NEXT_PUBLIC_COMSCORE_CLIENT_ID
  });

  const sendPlayStart = () =>
    marks.isAd
      ? streamSense.playVideoAdvertisement()
      : streamSense.playVideoContentPart({
          ns_st_ci: marks.talkId
        });

  function handle(getPayload, name) {
    switch (name) {
      case 'onTalk':
        marks = {};
        marks.talkId = getPayload()?.currentTalk;
        break;

      case 'onPlay':
        if (marks.talkId && !marks.isPlayed) {
          marks.isPlayed = true;
          sendVideoBeacon({
            c5: '02',
            c6: marks.talkId
          });
        }
        sendPlayStart();
        break;

      case 'onPause':
        streamSense.stop();
        break;

      case 'onPrerollStart':
      case 'onPostrollStart':
        marks.isAd = true;
        sendVideoBeacon({
          c5: name === 'onPrerollStart' ? '09' : '10'
        });
        sendPlayStart();
        break;

      case 'onPrerollComplete':
      case 'onPostrollComplete':
        marks.isAd = false;
        sendPlayStart();
        break;

      case 'onComplete':
        streamSense.stop();
        break;
      default:
        break;
    }

    return set(getPayload, name);
  }

  return config(handle, get, api);
};

export default middleware;
