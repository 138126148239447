/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: Update any types in this file to be more specific
// @ts-strict-ignore
import { secondsToMilliseconds } from 'date-fns';
import create, {
  EqualityChecker,
  GetState,
  PartialState,
  SetState,
  State,
  StateCreator,
  StateSelector,
  StoreApi,
  UseStore
} from 'zustand';
import { devtools } from 'zustand/middleware';

import { Video } from 'api';
import { client, identity, noop, pathOr, pipe } from 'f';
import { sendErrorToServices } from 'lib/logging';
import * as Select from 'lib/select';
import { formatAsTimestamp } from 'lib/util';

import { PlayerZoneConfig } from 'components/pages/embed/zones';
import comscoreMiddleware from './InternalPlayer/middleware/comscore-middleware';
import historyMiddleware from './InternalPlayer/middleware/history-middleware';
import youtubeAnalyticsMiddleware from './YouTubePlayer/youtube-middleware';
import type { MixpanelVideoDetails } from './analytics';
import { mixpanelMiddleware } from './analytics';
import { Language } from './index';

type DevToolsStateSetter<T> = (partial: PartialState<T>, name?: string) => void;

type DownloadFormat = 'audio' | 'video' | null;
type ContentState = 'preroll' | 'postroll' | 'content' | 'none';
type PlaybackMode = 'unstarted' | 'playing' | 'paused' | 'ended' | 'replayed';
type Subtitle = 'OFF' | string;
type Controls = 'custom' | 'native';

interface VideoPlayerState extends State {
  ad: any;
  audio: {
    mute: boolean;
    volume: number;
  };
  autoplayed?: boolean;
  shouldAutoplay: boolean;
  buffering: boolean;
  contentTime: number;
  currentTalk?: string;
  currentTalkDetails: Partial<Video>;
  currentTime: number;
  contentState: ContentState;
  downloadFormat: DownloadFormat;
  duration: number;
  features: {
    fullscreen: boolean;
    subtitle: boolean;
    volume: boolean;
    controls: Controls;
    playbackRate: boolean;
  };
  formattedTime: string;
  formattedDuration: string;
  fullscreen: boolean;
  hasAds: boolean;
  isAdBreak: boolean;
  isEmbed: boolean;
  languages: Array<Language>;
  mixpanelTalkDetails: MixpanelVideoDetails;
  mode: string;
  nativeLanguage: string;
  playbackMode: PlaybackMode;
  playbackRate: number;
  playbackRequested: boolean;
  playerZone: PlayerZoneConfig;
  poppedOut: boolean;
  preroll?: boolean;
  prerollDetails?: any;
  prerollRemaining?: number;
  prerollDuration?: number;
  postroll?: boolean;
  postrollDetails?: any;
  postrollRemaining?: number;
  postrollDuration?: number;
  ready: boolean;
  requestedCurrentTime: number;
  requestedMute: boolean;
  requestedPlaybackRate: number;
  requestedSubtitleLanguage: string;
  requestedVolume: number;
  roundedTime: number;
  roundedDuration: number;
  service: 'internal' | 'youtube' | 'unset';
  streamDuration: number;
  streamTime: number;
  subtitle: Subtitle;
  allSubtitles: Subtitle[];
  subtitleCue: string;
  talkShareDetails?: any;
  time: number;
  isTranscriptVisible?: boolean;
  transcriptLanguage?: string;
  youTubePlayer: YT.Player;
  isTooltipOpen: boolean;
  pauseCalled: boolean | null;
  playCalled: boolean | null;
}

export type VideoPlayerActions = {
  autoPlay: (payload: boolean, userInitiated?: boolean) => void;
  expressPlaybackIntent: (payload: boolean) => void;
  onAd: (ad: any) => void;
  onAdLoaded: () => void;
  onAdSkip: () => void;
  onAdClick: () => void;
  onAdCenterClick: () => void;
  onAddToList: () => void;
  onAddComment: () => void;
  onBufferState: (state: boolean) => void;
  onCanNativeFullScreen: (can: boolean) => void;
  onCanPlaybackRate: (can: boolean) => void;
  onCanSubtitle: (can: boolean) => void;
  onCanVolume: (can: boolean) => void;
  onComplete: (can: boolean) => void;
  onContentTime: (time: number) => void;
  onContentState: (state: ContentState) => void;
  onControls: (type: Controls) => void;
  onCurrentTime: (payload: number) => void;
  onDownload: (payload: DownloadFormat) => void;
  onDuration: (payload: number) => void;
  onEmbed: (payload: boolean, zone?: PlayerZoneConfig) => void;
  onError: (err: any) => void;
  onFullscreen: (shouldState: boolean) => void;
  onLanguages: (languages: Array<Language>) => void;
  onLike: () => void;
  onTakeawaysEntry: () => void;
  onMode: (mode: string) => string;
  onMuted: (payload?: any, userInitiated?: boolean) => void;
  onNativeLanguage: (nativeLanguage: string) => void;
  onPause: (payload?: any, userInitiated?: boolean) => void;
  onPlay: () => void;
  onPlaybackMode: (mode: PlaybackMode) => void;
  onPlaybackRate: (rate: number, userInitiated?: boolean) => void;
  onPopOut: () => void;
  onPostrollComplete: () => void;
  onPostrollRemaining: (time: number) => void;
  onPostrollStart: (postrollDetails: any) => void;
  onPrerollComplete: () => void;
  onPrerollRemaining: (time: number) => void;
  onPrerollStart: (prerollDetails: any) => void;
  onReady: (ready: boolean) => void;
  onSeek: (time: number) => void;
  onStreamAds: () => void;
  onStreamDuration: (duration: number) => void;
  onStreamHost: (host: string) => void;
  onStreamTime: (time: number) => void;
  onSubtitle: (cues) => void;
  onAllSubtitles: (cues) => void;
  onSubtitleCue: (cue) => void;
  onSubtitleSet: () => void;
  onTEDStart: () => void;
  onTalk: (talk: any) => void;
  onTalkComplete: () => void;
  onTalkShareStart: (service: string) => void;
  onTranscriptClick: () => void;
  onCloseTranscript: () => void;
  onTranscriptLanguage: (selectedLanguage: string) => void;
  onTranscriptToggle: (isTranscriptVisible: boolean) => void;
  onVolume: (payload?: any, userInitiated?: boolean) => void;
  registerPaRappa: (player: any) => void;
  registerYouTubePlayer: (player: YT.Player) => void;
  requestContentTime: (requestedContentTime: number) => void;
  requestCurrentTime: (requestedCurrentTime: number) => void;
  requestMute: (requestedMute: boolean, userInitiated?: boolean) => void;
  requestPause: () => void;
  requestPlay: () => void;
  requestPlaybackRate: (
    requestedPlaybackRate: number,
    userInitiated?: boolean
  ) => void;
  requestSubtitleLanguage: (requestedSubtitleLanguage: string) => void;
  requestVolume: (requestedVolume: number, userInitiated?: boolean) => void;
  reset: (payload: 'replay' | 'nextTalk') => void;
  setMixpanelTalkDetails: (mixpanelVideoDetails: MixpanelVideoDetails) => void;
  callPause: (payload: boolean | null) => void;
  callPlay: (payload: boolean | null) => void;
  setShouldAutoplay: (shouldAutoplay: boolean) => void;
};

const DEFAULT_STATE: VideoPlayerState = {
  ad: {
    adInfo: {
      id: null,
      title: null,
      advertiserName: null,
      duration: null,
      isSkippable: false,
      cues: []
    },
    contentSourceId: null,
    videoId: null,
    adParams: null,
    token: null
  },
  audio: {
    mute: true,
    volume: 0.5
  },
  autoplayed: false,
  shouldAutoplay: false,
  buffering: false,
  contentTime: 0,
  contentState: 'preroll',
  currentTime: 0,
  convertedTime: 0,
  currentTalk: null,
  currentTalkDetails: {},
  downloadFormat: null,
  duration: 0,
  features: {
    fullscreen: false,
    subtitle: false,
    volume: false,
    controls: 'custom',
    playbackRate: false
  },
  formattedTime: '00:00',
  formattedDuration: '00:00',
  fullscreen: false,
  hasAds: false,
  isAdBreak: false,
  isEmbed: false,
  languages: null,
  mixpanelTalkDetails: {} as MixpanelVideoDetails,
  mode: 'none',
  nativeLanguage: 'en',
  playbackMode: 'unstarted',
  playbackRate: 1,
  playbackRequested: false,
  playerZone: null,
  poppedOut: false,
  postrollRemaining: 0,
  postrollDuration: 0,
  prerollRemaining: 0,
  prerollDuration: 0,
  prerollDetails: null,
  postrollDetails: null,
  ready: false,
  requestedCurrentTime: null,
  requestedMute: null,
  requestedPlaybackRate: null,
  requestedSubtitleLanguage: null,
  requestedVolume: null,
  roundedTime: 0,
  roundedDuration: 0,
  service: 'unset',
  streamDuration: 0,
  streamTime: 0,
  subtitle: 'OFF',
  subtitleCue: '',
  subtitleCues: [],
  allSubtitles: [],
  time: 0,
  isTranscriptVisible: false,
  transcriptLanguage: '',
  youTubePlayer: null,
  isTooltipOpen: false,
  pauseCalled: null,
  playCalled: null
};

export type VideoPlayerStore = VideoPlayerState & VideoPlayerActions;

const noopMiddleware = fn => (get, set, api) => fn(get, set, api);

export type Middleware = (
  config: StateCreator<VideoPlayerStore>
) => (
  set: SetState<VideoPlayerStore>,
  get: GetState<VideoPlayerStore>,
  api: StoreApi<VideoPlayerStore>
) => VideoPlayerStore;

const applyMiddleware = pipe<any, any, any, any, any, any>(
  client || process.env.NODE_ENV === 'test'
    ? youtubeAnalyticsMiddleware()
    : identity,
  mixpanelMiddleware(),
  comscoreMiddleware(),
  historyMiddleware(),
  /*
   * make sure DevTools is last in this list (therefore last to be executed)
   * because it doesn't pass `name` on!
   */
  process.env.NODE_ENV === 'production' || !client
    ? noopMiddleware
    : x => devtools(x, 'VideoPlayer')
);

export const initializeStore = () => {
  return create<VideoPlayerStore>(
    applyMiddleware(
      (
        set: DevToolsStateSetter<VideoPlayerStore>,
        get: GetState<VideoPlayerStore>
      ) => ({
        ...DEFAULT_STATE,
        expressPlaybackIntent: (payload: boolean) => {
          return set(
            () => ({
              type: 'EXPRESS_PLAYBACK_INTENT',
              playbackRequested: payload,
              autoplayed: payload
            }),
            'expressPlaybackIntent'
          );
        },
        onAd: (payload: any): void =>
          set(
            () => ({
              ad: payload,
              isAdBreak: Boolean(payload.isAdBreak),
              postrollDuration: payload.postrollDuration || 0,
              prerollDuration: payload.prerollDuration || 0,
              hasAds: true
            }),
            'onAd'
          ),
        onAdLoaded: (): void =>
          set(
            () => ({
              hasAds: true
            }),
            'onAdLoaded'
          ),
        onAdSkip: (): void => set(noop, 'onAdSkip'),
        onAdClick: (): void => set(noop, 'onAdClick'),
        onAdCenterClick: (): void => set(noop, 'onAdCenterClick'),
        onAddToList: (): void => set(noop, 'onAddToList'),
        onAddComment: (): void => set(noop, 'onAddComment'),
        autoPlay: (payload: boolean, userInitiated = false): void =>
          set(
            () => ({
              autoplayed: payload,
              userInitiated
            }),
            'autoPlay'
          ),
        onBufferState: (payload: boolean): void =>
          set(
            () => ({
              buffering: payload
            }),
            'onBufferState'
          ),
        onCanNativeFullScreen: (payload: boolean): void =>
          set(
            () => ({
              features: {
                ...get().features,
                fullscreen: payload
              }
            }),
            'onCanNativeFullScreen'
          ),
        onCanPlaybackRate: (payload: boolean): void =>
          set(
            () => ({
              features: {
                ...get().features,
                playbackRate: payload
              }
            }),
            'onCanPlaybackRate'
          ),
        onCanSubtitle: (payload: boolean): void =>
          set(
            () => ({
              features: {
                ...get().features,
                subtitle: payload
              }
            }),
            'onCanSubtitle'
          ),
        onCanVolume: (payload: boolean): void =>
          set(
            () => ({
              features: {
                ...get().features,
                volume: payload
              }
            }),
            'onCanVolume'
          ),
        onContentTime: (payload: number): void =>
          set(
            () => ({
              contentTime: payload
            }),
            'onContentTime'
          ),
        onContentState: (payload: ContentState): void =>
          set(
            () => ({
              contentState: payload
            }),
            'onContentState'
          ),
        onControls: (payload: Controls): void =>
          set(
            () => ({
              features: {
                ...get().features,
                controls: payload
              }
            }),
            'onControls'
          ),
        onCurrentTime: (payload: number): void =>
          set(
            () => ({
              currentTime: payload,
              formattedTime: formatAsTimestamp(payload),
              roundedTime: Math.ceil(payload),
              time: payload,
              ...(get().contentState === 'content' && {
                convertedTime: secondsToMilliseconds(payload)
              })
            }),
            'onCurrentTime'
          ),
        onDownload: (payload: DownloadFormat): void =>
          set(
            () => ({
              downloadFormat: payload
            }),
            'onDownload'
          ),
        onDuration: (payload: number): void =>
          set(
            () => ({
              duration: payload,
              formattedDuration: formatAsTimestamp(Math.ceil(payload)),
              roundedDuration: Math.ceil(payload)
            }),
            'onDuration'
          ),
        onEmbed: (isEmbedded: boolean, playerZone?: PlayerZoneConfig): void =>
          set(
            () => ({
              isEmbed: isEmbedded,
              contentState: 'content',
              playerZone
            }),
            'onEmbed'
          ),
        onError: (error: any): void => {
          sendErrorToServices('player error:', error);
        },
        onLanguages: (languages: Array<Language>) =>
          set(
            () => ({
              languages
            }),
            'onLanguages'
          ),
        onLike: (): void => set(noop, 'onLike'),
        onTakeawaysEntry: (): void => set(noop, 'onTakeawaysEntry'),
        onMuted: (payload: boolean, userInitiated = false): void =>
          set(
            () => ({
              audio: {
                ...get().audio,
                mute: payload
              },
              userInitiated
            }),
            'onMuted'
          ),
        onMode: (payload: string): void => {
          set(
            () => ({
              mode: payload
            }),
            'onMode'
          );
        },
        onNativeLanguage: (nativeLanguage: string): void => {
          set(() => ({ nativeLanguage }), 'onNativeLanguage');
        },
        onPause: (userInitiated = false): void =>
          set(
            () => ({
              playbackMode: 'paused',
              userInitiated
            }),
            'onPause'
          ),
        onPlay: (): void => {
          return set(
            () => ({
              playbackMode: 'playing'
            }),
            'onPlay'
          );
        },
        onPlaybackMode: (payload: PlaybackMode): void => {
          return set(
            () => ({
              playbackMode: payload
            }),
            'onPlaybackMode'
          );
        },
        onTalkShareStart: (payload): void => {
          return set(
            () => ({
              talkShareDetails: payload
            }),
            'onTalkShareStart'
          );
        },

        onPlaybackRate: (payload: number, userInitiated = false): void =>
          set(
            () => ({
              playbackRate: payload,
              userInitiated
            }),
            'onPlaybackRate'
          ),

        onPopOut: (payload: boolean): void =>
          set(
            () => ({
              poppedOut: payload
            }),
            'onPopOut'
          ),
        onPostrollComplete: (payload): void => {
          set(
            () => ({
              postrollDetails: payload,
              postrollRemaining: 0,
              contentState: 'none',
              playbackMode: 'ended'
            }),
            'onPostrollComplete'
          );
        },
        onPostrollRemaining: (time: number): void => {
          set(() => ({ postrollRemaining: time }), 'onPostrollRemaining');
        },
        onPostrollStart: (payload): void => {
          set(
            () => ({ postrollDetails: payload, contentState: 'postroll' }),
            'onPostrollStart'
          );
        },
        onPrerollComplete: (payload): void => {
          set(
            () => ({
              prerollDetails: payload,
              prerollRemaining: 0,
              contentState: 'content'
            }),
            'onPrerollComplete'
          );
        },
        onPrerollRemaining: (time: number): void => {
          set(() => ({ prerollRemaining: time }), 'onPrerollRemaining');
        },
        onPrerollStart: (payload): void => {
          set(
            () => ({ prerollDetails: payload, contentState: 'preroll' }),
            'onPrerollStart'
          );
        },
        onReady: (ready: boolean): void => {
          return set(
            () => ({
              ready
            }),
            'onReady'
          );
        },
        onSeek: (time: number): void => {
          const { requestCurrentTime } = get();
          requestCurrentTime(time);
        },
        onService: (payload): void => {
          return set(
            () => ({
              service: Select.service(
                JSON.parse(pathOr<string>('{}', 'playerData')(payload))
              )
            }),
            'onService'
          );
        },
        onStreamAds: (payload: { preroll: any; postroll: any }): void =>
          set(
            () => ({
              ready: true,
              preroll: Boolean(payload && payload.preroll),
              postroll: Boolean(payload && payload.postroll)
            }),
            'onStreamAds'
          ),
        onStreamDuration: (payload: number): void =>
          set(
            () => ({
              streamDuration: payload
            }),
            'onStreamDuration'
          ),
        onStreamHost: (payload?: string): void =>
          set(() => ({
            streamHost: payload
          })),
        onStreamTime: (time: number): void =>
          set(() => ({ streamTime: time }), 'onStreamTime'),
        onSubtitle: (payload): void =>
          set(
            () => ({
              subtitleCues: payload
            }),
            'onSubtitle'
          ),
        onAllSubtitles: (payload): void =>
          set(
            () => ({
              allSubtitles: payload
            }),
            'onSubtitles'
          ),
        onSubtitleCue: (payload: string): void =>
          set(
            () => ({
              subtitleCue: payload
            }),
            'onSubtitleCue'
          ),
        onSubtitleSet: (): void => set(noop, 'onSubtitleSet'),
        onTalk: (payload: Partial<Video>): void =>
          set(
            () => ({
              currentTalk: payload.id,
              currentTalkDetails: payload
            }),
            'onTalk'
          ),
        onTalkComplete: (): void => set(noop, 'onTalkComplete'),
        onTEDStart: (): void => set(noop, 'onTedStart'),
        onFullscreen: (payload: boolean): void =>
          set(
            () => ({
              fullscreen: payload
            }),
            'onFullscreen'
          ),
        onTranscriptLanguage: (payload: string): void =>
          set(
            () => ({
              transcriptLanguage: payload
            }),
            'onTranscriptLanguage'
          ),
        onTranscriptToggle: (payload: boolean): void =>
          set(() => ({ isTranscriptVisible: payload }), 'onTranscriptToggle'),
        onTranscriptClick: (): void => set(noop, 'onTranscriptClick'),
        onCloseTranscript: (): void => set(noop, 'onCloseTranscript'),
        onVolume: (payload: number, userInitiated = false): void =>
          set(
            () => ({
              audio: {
                ...get().audio,
                volume: payload
              },
              userInitiated
            }),
            'onVolume'
          ),
        registerPaRappa: (parappa: any): void => {
          // @ts-ignore
          parappaMiddleware.parappa = parappa;
          set(() => ({}), 'registerPaRappa');
        },
        registerYouTubePlayer: (youTubePlayer: YT.Player): void => {
          set(() => ({ youTubePlayer }), 'registerYouTubePlayer');
        },
        requestCurrentTime: (requestedCurrentTime: number): void => {
          set(() => ({ requestedCurrentTime }), 'requestCurrentTime');
        },
        requestContentTime: (requestedContentTime: number): void => {
          set(
            () => ({ contentTime: requestedContentTime }),
            'requestContentTime'
          );
        },
        requestMute: (payload: boolean, userInitiated = false): void =>
          set(
            () => ({
              audio: {
                ...get().audio,
                mute: payload
              },
              userInitiated
            }),
            'requestMute'
          ),
        requestPause: (): void => {
          set(() => ({}), 'requestPause');
        },
        requestPlay: (): void => {
          set(() => ({}), 'requestPlay');
        },
        requestPlaybackRate: (
          requestedPlaybackRate: number,
          userInitiated = false
        ): void => {
          set(
            () => ({ requestedPlaybackRate, userInitiated }),
            'requestPlaybackRate'
          );
        },
        requestSubtitleLanguage: (requestedSubtitleLanguage: string): void => {
          set(
            () => ({
              requestedSubtitleLanguage,
              subtitle: requestedSubtitleLanguage
            }),
            'requestSubtitleLanguage'
          );
        },
        requestVolume: (
          requestedVolume: number,
          userInitiated = false
        ): void => {
          set(
            () => ({
              audio: {
                ...get().audio,
                volume: requestedVolume
              },
              userInitiated
            }),
            'requestVolume'
          );
        },
        callPause: (payload: boolean | null) =>
          set(() => ({
            pauseCalled: payload
          })),
        callPlay: (payload: boolean | null) =>
          set(() => ({
            playCalled: payload
          })),

        reset: (payload: 'replay' | 'nextTalk'): void => {
          return set(
            () => ({
              ad: {},
              contentState: payload === 'replay' ? 'content' : 'preroll',
              time: 0,
              buffering: false,
              contentTime: 0,
              currentTime: 0,
              formattedTime: '00:00',
              roundedTime: 0,
              requestedCurrentTime: 0,
              streamTime: 0,
              ready: false,
              playbackMode: payload === 'replay' ? 'replayed' : 'unstarted',
              duration: payload === 'nextTalk' ? 0 : get().duration,
              streamDuration: payload === 'nextTalk' ? 0 : get().streamDuration,
              preroll: false,
              postroll: false,
              prerollDetails: {},
              postrollDetails: {},
              prerollDuration:
                payload === 'nextTalk' ? 0 : get().prerollDuration,
              postrollDuration:
                payload === 'nextTalk' ? 0 : get().postrollDuration,
              subtitleCues: payload === 'nextTalk' ? [] : get().subtitleCues,
              subtitleCue: ''
            }),
            'reset'
          );
        },
        /*
          Mixpanel needs information from multiple sources: videoData, parsed(videoData.playerData),
          talkpage context, source context, Navigator, and router. Because of this need from so many
          sources creating a store slice to catch all necessary information that can then be used in the
          mixpanel-middleware as needed.
        */
        setMixpanelTalkDetails: (payload: MixpanelVideoDetails): void => {
          set(
            () => ({ mixpanelTalkDetails: payload }),
            'setMixpanelTalkDetails'
          );
        },
        setShouldAutoplay: (shouldAutoplay: boolean): void =>
          set(
            () => ({
              shouldAutoplay,
              autoplayed: shouldAutoplay
            }),
            'setShouldAutoplay'
          )
      })
    )
  );
};

let videoPlayerStore: [UseStore<VideoPlayerStore>, StoreApi<VideoPlayerStore>];
function getStore() {
  // Always make a new store if server, otherwise state is shared between requests
  if (!client) {
    return initializeStore();
  }

  // Create store if unavailable on the client and set it on the window object
  if (!videoPlayerStore) {
    videoPlayerStore = initializeStore();
  }

  return videoPlayerStore;
}

export default function useVideoPlayerStore<T>(
  selector: StateSelector<VideoPlayerStore, T>,
  equalityFn?: EqualityChecker<T>
): T {
  const [useStore] = getStore();

  return useStore<T>(selector, equalityFn);
}
