/* eslint-disable @typescript-eslint/no-explicit-any */
import { evolve } from 'f';
import mixpanel from 'lib/analytics/mixpanel';
import TED from 'lib/ted';
import nookies from 'nookies';
import qs from 'qs';
import * as React from 'react';
import { useDidMount } from 'rooks';

export const getLoggedIn = (ctx?: Parameters<typeof nookies.get>[0]): boolean =>
  Boolean(nookies.get(ctx).ted_session);
export const getCurrentUserId = (): string => nookies.get()._ted_user_id;

const actionDeserializations = {
  variables: {
    secondsPlayed: Number,
    shouldFollow: (x: string) => x === 'true'
  }
};

export function getSignInUrl(params: Record<string, string> = {}): string {
  // Process params to ensure URLs are valid and domain replacements are applied
  const processedParams = Object.entries(params).reduce(
    (acc, [key, value]) => {
      // Process potential URL values (referrer, etc.)
      if (
        typeof value === 'string' &&
        (value.includes('http') ||
          value.includes('www.') ||
          value.includes('ted.com'))
      ) {
        // Normalize the URL: ensure https protocol and replace specific domains
        let normalizedUrl = value;

        // Add protocol if missing
        if (!normalizedUrl.startsWith('http')) {
          normalizedUrl = `https://${normalizedUrl}`;
        }

        // Ensure HTTPS (replace HTTP with HTTPS)
        normalizedUrl = normalizedUrl.replace(/^http:/i, 'https:');

        // Replace zenith-prod-alt.ted.com with www.ted.com
        normalizedUrl = normalizedUrl.replace(
          /zenith-prod-alt\.ted\.com/gi,
          'www.ted.com'
        );

        acc[key] = normalizedUrl;
      } else {
        acc[key] = value;
      }
      return acc;
    },
    {} as Record<string, string>
  );

  // https://auth.ted.com/session/new?context=ted.www%2Ftalk-page&referrer=http%3A%2F%2Flocal.ted.com%3A4000%2Ftalks%2Flisa_bu_how_books_can_open_your_mind%3Fid%3D1755%26secondsPlayed%3D0&action=saveVideoToYourList
  return `${TED('signInUrl')}${qs.stringify(processedParams, { addQueryPrefix: true })}`;
}

export function useAuthenticated(): boolean {
  const loggedIn = React.useRef(getLoggedIn());
  const userId = getCurrentUserId();
  if (loggedIn.current) {
    // Identifies the User so Mixpanel can keep track of them
    mixpanel.identify(userId);
    // We use Register Once to set this as a super property
    // Because we have signed_in events in other places
    // Which we don't want to overwrite
    // Ref - https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#setting-super-properties
    mixpanel.register({
      signed_in: loggedIn
    });
  }
  return loggedIn.current || false;
}

export enum AuthRedirectType {
  Like = 'Like',
  AddToList = 'AddToList',
  Comment = 'Comment',
  Summary = 'Summary',
  SaveTakeaway = 'SaveTakeaway',
  ViewTakeaways = 'ViewTakeaways'
}

const AUTH_REDIRECT_DATA = {
  [AuthRedirectType.Like]: {
    authContext: 'ted.www/like',
    actionType: 'LIKE_ITEM'
  },
  [AuthRedirectType.AddToList]: {
    authContext: 'ted.www/watch-later',
    actionType: 'SAVE_VIDEO_TO_YOUR_LIST'
  },
  [AuthRedirectType.Comment]: {
    authContext: 'ted.www/comment',
    actionType: 'COMMENT_OR_REPLY'
  },
  [AuthRedirectType.Summary]: {
    authContext: 'ted.www/summary',
    actionType: 'SUMMARY'
  },
  [AuthRedirectType.SaveTakeaway]: {
    authContext: 'ted.www/takeaways',
    actionType: 'SAVE_TAKEAWAY'
  },
  [AuthRedirectType.ViewTakeaways]: {
    authContext: 'ted.www/takeaways',
    actionType: 'VIEW_TAKEAWAYS'
  }
};

export function createSignInRedirectUrl(
  redirectType: AuthRedirectType,
  variables: any
): string {
  const { authContext, actionType } = AUTH_REDIRECT_DATA[redirectType];
  const { origin, pathname, search } = window.location;

  return getSignInUrl({
    context: authContext,
    referrer: `${origin}${pathname}${qs.stringify(
      {
        ...qs.parse(search, {
          ignoreQueryPrefix: true
        }),
        action: {
          type: actionType,
          variables
        }
      },
      { addQueryPrefix: true }
    )}`
  });
}

/*
 * Executes a function side-effect for any URL redirect actions that match the redirectType given
 */
export function useActionOnRedirect(
  redirectType: AuthRedirectType,
  func: (variables: any) => Promise<unknown>
): void {
  useDidMount(() => {
    const { type: redirectAction, variables: redirectVariables } =
      getRedirectAction();

    if (
      AUTH_REDIRECT_DATA[redirectType].actionType === redirectAction &&
      func
    ) {
      func(redirectVariables);
      stripRedirectActionFromUrl();
    }
  });
}

function stripRedirectActionFromUrl() {
  const { href, origin, pathname, search } = window.location;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { action, ...cleanedSearchParams } = qs.parse(search, {
    ignoreQueryPrefix: true
  });

  const newUrl = `${origin}${pathname}${qs.stringify(cleanedSearchParams, {
    addQueryPrefix: true
  })}`;

  if (href !== newUrl) {
    window.history.replaceState(null, '', newUrl);
  }
}

const getRedirectAction = (): { type?: string; variables?: any } => {
  if (typeof window === 'undefined') return {};

  const { search } = window.location;
  const urlParams = qs.parse(search, { ignoreQueryPrefix: true });

  return evolve(actionDeserializations, urlParams?.action ?? {});
};
