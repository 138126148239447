import mixpanelMiddleware from './mixpanel-middleware';
import {
  sendPlayerStart,
  sendTalkDownloaded,
  sendPlayerPreRollStart,
  sendPlayerPreRollComplete,
  sendPlayerPostRollStart,
  sendPlayerPostRollComplete,
  sendPlayerComplete,
  sendPlayerProgressTime,
  sendPlayerPaused,
  sendTalkReadTranscript,
  sendTalkAddToList,
  sendTalkAddToLike,
  sendSetSubtitleLanguage,
  sendPlayerSetPlaybackSpeed
} from './mixpanel-events';

import {
  TalkMarks,
  RemainderPct,
  RemainderTime,
  PlayerMarks,
  MixpanelVideoDetails
} from './mixpanel.types';

export type {
  TalkMarks,
  RemainderPct,
  RemainderTime,
  PlayerMarks,
  MixpanelVideoDetails
};

export {
  mixpanelMiddleware,
  sendPlayerStart,
  sendTalkDownloaded,
  sendPlayerPreRollStart,
  sendPlayerPreRollComplete,
  sendPlayerPostRollStart,
  sendPlayerPostRollComplete,
  sendPlayerComplete,
  sendPlayerProgressTime,
  sendPlayerPaused,
  sendTalkReadTranscript,
  sendTalkAddToList,
  sendTalkAddToLike,
  sendSetSubtitleLanguage,
  sendPlayerSetPlaybackSpeed
};
