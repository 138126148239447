// @ts-strict-ignore
import {
  F,
  T,
  add,
  adjust,
  all,
  allPass,
  always,
  any,
  anyPass,
  append,
  applySpec,
  assoc,
  assocPath,
  both,
  clamp,
  clone,
  complement,
  compose,
  concat,
  converge,
  curry,
  curryN,
  dec,
  defaultTo,
  difference,
  dissoc,
  divide,
  drop,
  dropLast,
  either,
  endsWith,
  equals,
  filter,
  find,
  findIndex,
  flatten,
  flip,
  forEach,
  fromPairs,
  groupBy,
  groupWith,
  has,
  head,
  identical,
  identity,
  ifElse,
  inc,
  includes,
  indexBy,
  indexOf,
  init,
  intersection,
  intersperse,
  is,
  isEmpty,
  isNil,
  join,
  keys,
  last,
  lastIndexOf,
  length,
  lens,
  lensIndex,
  lensPath,
  lensProp,
  map,
  match,
  max,
  maxBy,
  mean,
  median,
  merge,
  min,
  minBy,
  modulo,
  multiply,
  negate,
  none,
  not,
  nth,
  omit,
  partial,
  path,
  pathOr,
  pick,
  pickAll,
  pipe,
  pluck,
  prepend,
  product,
  prop,
  propEq,
  propIs,
  propOr,
  range,
  reduce,
  reject,
  repeat,
  replace,
  reverse,
  set,
  sort,
  sortBy,
  split,
  splitEvery,
  startsWith,
  subtract,
  sum,
  symmetricDifference,
  tail,
  take,
  takeLast,
  tap,
  test,
  times,
  toLower,
  toPairs,
  toString,
  toUpper,
  trim,
  type,
  uniq,
  uniqWith,
  update,
  values,
  without,
  zip,
  zipObj
} from 'rambda';
import sw from 'switch-fn';

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
export function noop(..._x: unknown[]): any {}
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-explicit-any */
export const execute =
  (fn: (...p: unknown[]) => any) =>
  (data: unknown): any =>
    fn(data);
/* eslint-enable @typescript-eslint/no-explicit-any */

export const client = typeof window !== 'undefined';

export const fromEvent = path(['target', 'value']);
export const switchFn = sw;

export const gte =
  (comp: number) =>
  (val: number): boolean =>
    val >= comp;
export const lte =
  (comp: number) =>
  (val: number): boolean =>
    val <= comp;
export const gt =
  (comp: number) =>
  (val: number): boolean =>
    val > comp;
export const lt =
  (comp: number) =>
  (val: number): boolean =>
    val < comp;

export const sortWith = curryN(2, function sortWith(fns, list) {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  return Array.prototype.slice.call(list, 0).sort((a: any, b: any) => {
    /* eslint-enable @typescript-eslint/no-explicit-any */
    let result = 0;
    let i = 0;
    while (result === 0 && i < fns.length) {
      result = fns[i](a, b);
      i += 1;
    }
    return result;
  });
});

type ArrayOrOneArg<T> = T | T[];
export function arrayOrOne<T>(x: ArrayOrOneArg<T>): T {
  // @ts-ignore
  if (x === 'undefined') return undefined;

  return Array.isArray(x) ? head(x) : x;
}

const appProtocol = process.env.NEXT_PUBLIC_APP_PROTOCOL || 'http';
const appHost = process.env.NEXT_PUBLIC_APP_HOST || 'local.ted.com';
const appPort = process.env.NEXT_PUBLIC_APP_PORT || '4000';

const baseUrl = `${appProtocol}://${appHost}`;

const portSuffix =
  process.env.NEXT_PUBLIC_APP_PORT && appProtocol === 'http'
    ? `:${appPort}`
    : '';

export const host = client
  ? `${window.location.protocol}//${window.location.host}`
  : `${baseUrl}${portSuffix}`;

// Liberally borrowed from [ramda](https://github.com/ramda/ramda/blob/7c492b64e0abb822aa4f3033333d5740b62c45f5/source/evolve.js)
// with slight modifications
const evolve = curry(function evolve(transformations, object) {
  const result = object instanceof Array ? [] : {};

  Object.keys(object).reduce((acc, key) => {
    const transformation = transformations[key];
    const transformationType = typeof transformation;
    acc[key] =
      transformationType === 'function'
        ? transformation(object[key])
        : transformation && transformationType === 'object'
          ? evolve(transformation, object[key])
          : object[key];

    return result;
  }, result);

  return result;
});

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export {
  F,
  T,
  add,
  adjust,
  all,
  allPass,
  always,
  any,
  anyPass,
  append,
  applySpec,
  assoc,
  assocPath,
  both,
  clamp,
  clone,
  complement,
  compose,
  concat,
  converge,
  curry,
  curryN,
  dec,
  defaultTo,
  difference,
  dissoc,
  divide,
  drop,
  dropLast,
  either,
  endsWith,
  equals,
  evolve,
  filter,
  find,
  findIndex,
  flatten,
  flip,
  forEach,
  fromPairs,
  groupBy,
  groupWith,
  has,
  head,
  identical,
  identity,
  ifElse,
  inc,
  includes,
  indexBy,
  indexOf,
  init,
  intersection,
  intersperse,
  is,
  isEmpty,
  isNil,
  join,
  keys,
  last,
  lastIndexOf,
  length,
  lens,
  lensIndex,
  lensPath,
  lensProp,
  map,
  match,
  max,
  maxBy,
  mean,
  median,
  merge,
  min,
  minBy,
  modulo,
  multiply,
  negate,
  none,
  not,
  nth,
  omit,
  partial,
  path,
  pathOr,
  pick,
  pickAll,
  pipe,
  pluck,
  prepend,
  product,
  prop,
  propEq,
  propIs,
  propOr,
  range,
  reduce,
  reject,
  repeat,
  replace,
  reverse,
  set,
  sort,
  sortBy,
  split,
  splitEvery,
  startsWith,
  subtract,
  sum,
  symmetricDifference,
  tail,
  take,
  takeLast,
  tap,
  test,
  times,
  toLower,
  toPairs,
  toString,
  toUpper,
  trim,
  type,
  uniq,
  uniqWith,
  update,
  values,
  without,
  zip,
  zipObj
};
