// @ts-strict-ignore
// extracts the playlist slug from a referrer if one exists
export default function getPlaylistReferrer(
  referrer: string | string[]
): string {
  const prefix = 'playlist-';
  const referrerStr = referrer && referrer.toString();

  if (referrerStr && referrerStr.indexOf(prefix) === 0) {
    const playlistSlug = referrerStr.substring(
      prefix.length,
      referrerStr.length
    );
    return playlistSlug;
  }

  return null;
}
