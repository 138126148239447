import storage from 'local-storage-fallback';
import { createContext, useContext, useEffect, type JSX } from 'react';

import { NavUserInformationQuery, useNavUserInformationQuery } from 'api';

import { useAuthenticated } from 'lib/auth';
import { useDidMount } from 'lib/hooks/useDidMount';
import getSailthruVars from 'lib/sailthru/request-user-var-sailthru';
import { useRouter } from 'next/router';

type SailthruCookieContextProps = object;

type SailthruCookieProviderProps = {
  children: React.ReactNode;
};

const SailthruCookieContextContext = createContext(
  {} as SailthruCookieContextProps
);

export function useSailthruCookie() {
  return useContext(SailthruCookieContextContext);
}

export const SailthruCookieProvider = ({
  children
}: SailthruCookieProviderProps): JSX.Element => {
  const isLogged = useAuthenticated();
  const { pathname } = useRouter();

  const { data } = useNavUserInformationQuery({
    ssr: true,
    skip: !isLogged
  });
  const isMounted = useDidMount();
  const user = data?.viewer as NavUserInformationQuery['viewer'];
  const cookieValue = storage.getItem('sailthru_hid');
  const hasCookieValue = !!cookieValue;

  useEffect(() => {
    if (!isLogged && hasCookieValue) {
      storage.removeItem('sailthru_hid');
    }

    if (!isMounted || !user?.primaryEmailAddress || pathname.includes('embed'))
      return;

    const fetchSailthruCookieValue = async () => {
      try {
        const sailthruVars = await getSailthruVars({
          id: user?.primaryEmailAddress,
          fields: { keys: 1 }
        });

        if (
          sailthruVars?.keys?.cookie &&
          sailthruVars.keys.cookie !== cookieValue
        ) {
          storage.setItem('sailthru_hid', sailthruVars.keys.cookie);
        }
      } catch (error) {
        console.error('Error fetching sailthru cookie:', error);
      }
    };

    fetchSailthruCookieValue();
  }, [isMounted, user, cookieValue, isLogged, hasCookieValue]);

  return (
    <SailthruCookieContextContext.Provider value={{}}>
      {children}
    </SailthruCookieContextContext.Provider>
  );
};
