import * as React from 'react';
import BackwardE from './backwards-e.svg';
import FallenD from './fallen-d.svg';
import LockedD from './locked-d.svg';
import MissingE from './missing-e.svg';
import Standard from './standard.svg';

type ErrorDetails = {
  title: string;
  headline: string;
  image: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  message: string;
  error?: string;
  hasSearch?: boolean;
};

type ErrorCode =
  | 401
  | 403
  | 404
  | 405
  | 410
  | 422
  | 429
  | 500
  | 501
  | 502
  | 503
  | 504
  | 508;

const defaultError: ErrorDetails = {
  title: 'Oh no',
  headline: 'Sorry, something went wrong.',
  image: Standard,
  message:
    'Please [contact us](https://www.ted.com/contact) and let us know what happened.',
  hasSearch: true
};

const errors: Partial<Record<ErrorCode, ErrorDetails>> = {
  401: {
    title: 'Not Authorized',
    headline: "Sorry, you're not authorized to see that.",
    image: LockedD,
    message:
      "If you've reached this page in error, please [contact us](https://www.ted.com/contact) and let us know what happened.",
    hasSearch: false
  },
  403: {
    title: 'Forbidden',
    headline: "Sorry, you can't access that.",
    image: LockedD,
    message:
      "If you've reached this page in error, please [contact us](https://www.ted.com/contact) and let us know what happened.",
    hasSearch: false
  },
  404: {
    title: 'Not Found',
    headline: "Sorry, we can't find that.",
    image: MissingE,
    message: "Perhaps we can help you find the content you're looking for:",
    hasSearch: true
  },
  405: {
    title: 'Method Not Allowed',
    headline: "Sorry, that's not supported.",
    image: BackwardE,
    message:
      'Please [contact us](https://www.ted.com/contact) and let us know what happened.',
    hasSearch: false
  },
  410: {
    title: 'Gone',
    headline: 'Sorry, this content is no longer available.',
    image: MissingE,
    message: 'Can we help you find other content of interest?',
    hasSearch: true
  },
  422: {
    title: 'Unprocessable Entity',
    headline: "Sorry, that's not supported.",
    image: BackwardE,
    message:
      'Please [contact us](https://www.ted.com/contact) and let us know what happened.',
    hasSearch: false
  },
  429: {
    title: 'Too Many Requests',
    headline: "Sorry, we're experiencing technical difficulties.",
    image: FallenD,
    message:
      'Please [contact us](https://www.ted.com/contact) and let us know what happened.',
    hasSearch: false
  },
  500: {
    title: 'Internal Server Error',
    headline: "Sorry, we're experiencing technical difficulties.",
    image: FallenD,
    message: "We've logged this error and will fix it as soon as we can.",
    hasSearch: false
  },
  501: {
    title: 'Not Implemented',
    headline: "Sorry, that's not supported.",
    image: MissingE,
    message: "We've logged this error and will fix it as soon as we can.",
    error: 'the request method was not recognized.',
    hasSearch: false
  },
  502: {
    title: 'Bad Gateway',
    headline: "Sorry, we're experiencing technical difficulties.",
    image: FallenD,
    message: "We've logged this error and will fix it as soon as we can.",
    error: 'the request method was not recognized.',
    hasSearch: false
  },
  503: {
    title: 'Service Unavailable',
    headline: "Sorry, we're experiencing technical difficulties.",
    image: FallenD,
    message: "We've logged this error and will fix it as soon as we can.",
    error: 'the server is temporarily unavailable.',
    hasSearch: false
  },
  504: {
    title: 'Gateway Timeout',
    headline: "Sorry, we're experiencing technical difficulties.",
    image: FallenD,
    message: "We've logged this error and will fix it as soon as we can.",
    error: 'an upstream server did not respond in time.',
    hasSearch: false
  },
  508: {
    title: 'Loop Detected',
    headline: "Sorry, we're experiencing technical difficulties.",
    image: FallenD,
    message: "We've logged this error and will fix it as soon as we can.",
    error: 'the server encountered a loop.',
    hasSearch: false
  }
};

export default function getErrorDetails(
  statusCode: string | number
): ErrorDetails {
  const code = Number(statusCode) as ErrorCode;
  return errors[code] || defaultError;
}
