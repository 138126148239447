const VWO_UUID_COOKIE_NAME = '_vwo_uuid_v2';

const MY_LIBRARY_FEATURE_KEY = 'my-library';

export const FEATURE_FLAGS = {
  VWO_UUID_COOKIE_NAME,
  MY_LIBRARY_FEATURE_KEY
} as const;

export const VWO_FEATURED_URL_PARAM = 'vwo=featured';
