import { useEffect } from 'react';

const VHProvider = ({
  children
}: {
  children: React.ReactNode;
}): React.ReactNode => {
  useEffect(() => {
    function updateVhUnitCSSVariable() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    // vh unit fix
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/#css-custom-properties-the-trick-to-correct-sizing
    updateVhUnitCSSVariable();
    window.addEventListener('resize', updateVhUnitCSSVariable);

    return () => {
      window.removeEventListener('resize', updateVhUnitCSSVariable);
    };
  }, []);
  return children;
};

export default VHProvider;
