type ViewCountProps = {
  count: number;
  children: React.ReactNode;
};

export default function ViewCount({ count, children }: ViewCountProps) {
  if (count < 10000) {
    return <></>;
  }

  return <>{children}</>;
}
