import { PhotoSize } from 'api';

type AspectRatio = '16x9' | '4x3' | '1x1' | '2x1' | string;

type MinimalImage = { description?: string } & Pick<
  PhotoSize,
  'aspectRatio' | 'url'
>;

export type MinimalPrimaryImageSet = Array<
  { __typename?: 'PhotoSize' } & Pick<PhotoSize, 'url' | 'aspectRatio'>
>;

const particularAspectRatio = (aspectRatio: AspectRatio = '16x9') => {
  return (images: MinimalPrimaryImageSet): MinimalImage | undefined => {
    return images.find(img => img.aspectRatio?.name === aspectRatio);
  };
};

export const image = (
  aspectRatio: AspectRatio = '16x9'
): ((images: MinimalPrimaryImageSet) => MinimalImage) => {
  return (images: MinimalPrimaryImageSet): MinimalImage => {
    const matchingImage = particularAspectRatio(aspectRatio)(images);
    return matchingImage || images[0];
  };
};

export const primaryImage = (
  aspectRatio: AspectRatio = '16x9'
): ((data: { primaryImageSet: MinimalPrimaryImageSet }) => MinimalImage) => {
  return (data: { primaryImageSet: MinimalPrimaryImageSet }): MinimalImage => {
    return image(aspectRatio)(data.primaryImageSet);
  };
};

export const service = (data: {
  id?: string;
  resources?: { hls?: { stream?: unknown } };
  external?: { service?: string };
}): string => {
  if (!data.id) {
    return 'unset';
  }

  if (data.resources?.hls?.stream) {
    return 'internal';
  }

  return (data.external?.service || 'unset').toLowerCase();
};
