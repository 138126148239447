// @ts-strict-ignore
import { client } from 'f';
import { useLocalStorage } from 'lib/hooks/useLocalStorage';
import nookies, { setCookie } from 'nookies';

const currentLocation = (client && location?.href) || '';
const previousLocation = (client && document?.referrer) || '';
let stash;

export function useSetSourceContext(itemName: string): (value: string) => void {
  const [, setStorageValue] = useLocalStorage(itemName, null);
  return setStorageValue;
}

export function useSetCTAContext(itemName: string) {
  const [, setStorageValue] = useLocalStorage(itemName, null);
  return setStorageValue;
}

function extractHost(url: string): string {
  const matches = String(url).match(/^\w+:\/\/([^/]+)/);
  return matches && matches[1];
}

export function extractPath(url: string): string {
  const matches = String(url).match(/^(?:\w+:)?(?:\/\/[^/]+)?((?:\/[^?/#]*)+)/);
  return matches && matches[1];
}

function getFromValue(value: string): string {
  const matches = String(value).match(/^([^@]+)@(.*)/);
  const match = matches ? matches[1] : null;
  return match;
}

const clearStash = () => {
  stash = undefined;
};

const setStash = (value: string) => {
  if (client) {
    stash = value;
    window.setTimeout(clearStash, 30000);
  }
};

const getFromCookie = () => {
  return getFromValue(nookies.get().sourceContext);
};

const prepareContext = (context: string): string => {
  return String(context).replace('@', '');
};

export function setHomepageRibbonSourceContext(ribbonTitle: string): void {
  const ribbonSlug = ribbonTitle?.toLowerCase().replace(/\W+/g, '-');
  const sourceContext = `homepage-${ribbonSlug}`;

  setSourceContext('/', sourceContext);
}

export function setSourceContext(href: string, context: string): null {
  const path = extractPath(href);
  const value = `${prepareContext(context)}@${path}`;

  if (path) {
    setStash(value);
    setCookie(null, 'sourceContext', value, {
      // expires: 30, // TODO: add valid expiration
      path
    });
  }

  return null;
}

export default function sourceContext(): string {
  const sc = getFromValue(stash) || getFromCookie();
  const referrerHost = extractHost(previousLocation);
  const locationHost = extractHost(currentLocation);

  if (sc) return sc;
  if (previousLocation === '') return 'direct';
  if (referrerHost === locationHost) return 'internal';

  return 'external';
}
