// @ts-strict-ignore
import { getLoggedIn } from 'lib/auth';
import { addWatchedTalk } from 'lib/history';

import { Middleware } from '../../store';

type GetPlayLoad = () => { name: string };

const middleware = (): Middleware => store => (set, get, api) =>
  store(
    (getPayload: GetPlayLoad, name?: string) => {
      const payload = getPayload();
      const state = get();

      if (name === 'onPlay') {
        if (state && payload) {
          const { currentTalk, autoplayed } = state;
          const isLoggedIn = getLoggedIn();
          if (isLoggedIn) {
            addWatchedTalk(currentTalk, isLoggedIn, autoplayed);
          }
        }
      }

      return set(getPayload);
    },
    get,
    api
  );

export default middleware;
