// @ts-strict-ignore
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';

const SHOW_LOADER_AFTER = 500;

export function usePageLoader(): void {
  // Global page loader bar
  const router = useRouter();

  useEffect(() => {
    let pageLoaderTimeout;

    router.events.on('routeChangeStart', (_, { shallow }) => {
      if (!shallow) {
        // Show loader only if it takes more than 500ms
        clearTimeout(pageLoaderTimeout);
        pageLoaderTimeout = setTimeout(() => {
          NProgress.start();
        }, SHOW_LOADER_AFTER);
      }
    });

    router.events.on('routeChangeComplete', () => {
      clearTimeout(pageLoaderTimeout);
      NProgress.done();
    });

    router.events.on('routeChangeError', () => {
      clearTimeout(pageLoaderTimeout);
      NProgress.done();
    });
  }, []);
}
