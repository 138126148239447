import { useEffect, useState } from 'react';

/**
 * Check if a component was mounted and force a rerender
 */
export function useDidMount(): boolean {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);
  return isMounted;
}
