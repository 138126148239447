'use client';
import { PrismicPreview as NextPrismicPreview } from '@prismicio/next';
import { useRouter } from 'next/router';
import { repositoryName } from 'prismicio';

import type { JSX } from 'react';

type Props = { children: React.ReactNode };

export const PrismicPreview = ({ children }: Props): JSX.Element => {
  const router = useRouter();
  if (router.pathname.includes('slice-simulator')) return <>{children}</>;
  return (
    <NextPrismicPreview repositoryName={repositoryName}>
      {children}
    </NextPrismicPreview>
  );
};

export default PrismicPreview;
