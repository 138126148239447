import { throttle } from 'lodash-es';

import { getLoggedIn } from 'lib/auth';
import mixpanel from 'lib/analytics/mixpanel';
import { trackVWOCustomConversionById } from 'lib/analytics/vwo';

import { VideoPlayerStore } from 'components/video-player/store';

import { UniversalPropertiesOverride } from './mixpanel.types';

// VWO Goal Marks set on the VWO dashboard https://app.vwo.com/#/test/ab/112/edit/goals
const goalMarks = {
  25: 211,
  50: 212,
  75: 213,
  95: 214
};

const getPlayerPresentation = (
  isFullscreen: VideoPlayerStore['fullscreen']
): string => (isFullscreen ? 'fullscreen' : 'inline');

const universalProperties = (
  state: VideoPlayerStore,
  payload?: UniversalPropertiesOverride
) => {
  const {
    subtitle,
    playbackRate,
    streamHost,
    autoplayed,
    mixpanelTalkDetails: {
      content_discovery_context,
      talk_id,
      talk_slug,
      video_duration,
      video_topics,
      video_post_date,
      talkstar_context,
      talk_language,
      system_language,
      product,
      talk_type,
      playlist_slug
    }
  } = state;

  return {
    talk_id,
    talk_slug,
    video_duration,
    video_topics,
    video_post_date,
    talkstar_context,
    talk_language,
    system_language,
    product,
    talk_type,
    subtitle_language: subtitle,
    playlist_slug,
    playback_rate: payload?.playbackRate || playbackRate,
    stream_host: streamHost,
    content_discovery_context,
    autoplay_state: autoplayed
  };
};

const sendPlayerStart = (state: VideoPlayerStore): void => {
  const {
    fullscreen,
    transcriptLanguage,
    contentState,
    mixpanelTalkDetails: {
      recommendation_id,
      language_parameter_value,
      transcript_language
    }
  } = state;

  mixpanel.track('player_start', {
    ...universalProperties(state),
    recommendation_id,
    /*
      - if transcriptLanguage from store is set use that
      - if no transcriptLanguage, default transcript_language populated from talk page context language (language query param)
      - talk page context language works by defaulting the transcript select component selectionIndex regardless if the talk is translated in the language WEB-494
    */
    transcript_language: transcriptLanguage || transcript_language,
    language_parameter_value,
    content_state: contentState,
    player_presentation: getPlayerPresentation(fullscreen)
  });
};

const sendTalkMuteToggle = throttle(
  (state: VideoPlayerStore, payload): void => {
    const {
      audio: { mute, volume },
      userInitiated
    } = payload;

    const {
      roundedTime: action_time,
      mixpanelTalkDetails: { recommendation_id, media_type }
    } = state;

    const formattedVolume = Math.round(volume * 100);

    if (!userInitiated) return;

    mixpanel.track(mute ? 'player_mute' : 'player_unmute', {
      ...universalProperties(state),
      action_time,
      recommendation_id,
      media_type,
      muted: mute,
      volume: formattedVolume
    });
  },
  1000
);

const sendTalkVolumeChange = throttle(
  (state: VideoPlayerStore, payload): void => {
    const {
      audio: { mute, volume },
      userInitiated
    } = payload;

    if (!userInitiated) return;

    const {
      roundedTime: action_time,
      mixpanelTalkDetails: { recommendation_id, media_type }
    } = state;

    const formattedVolume = Math.round(volume * 100);

    mixpanel.track('player_volume_change', {
      ...universalProperties(state),
      action_time,
      recommendation_id,
      media_type,
      muted: mute,
      volume: formattedVolume
    });
  },
  1000,
  { leading: false }
);

const sendToggledAutoplay = (state: VideoPlayerStore, payload): void => {
  const { userInitiated } = payload;

  if (!userInitiated) return;

  mixpanel.track('set_autoplay', {
    ...universalProperties(state)
  });
};

const sendTalkDownloaded = (
  state: VideoPlayerStore,
  payload: VideoPlayerStore['downloadFormat']
): void => {
  const { roundedTime: action_time } = state;

  mixpanel.track('talk_downloaded', {
    ...universalProperties(state),
    action_time,
    download_type: payload
  });
};

const sendPlayerLearnMore = (state: VideoPlayerStore): void => {
  const {
    fullscreen,
    mixpanelTalkDetails: { recommendation_id },
    ad
  } = state;

  const adId =
    ad?.adInfo?.creativeId && ad?.adInfo?.lineItemId
      ? `${ad?.adInfo?.creativeId}, ${ad?.adInfo?.lineItemId}`
      : '';
  const adIsSkippable = ad?.isSkippable;

  mixpanel.track('player_learn-more', {
    ...universalProperties(state),
    recommendation_id,
    ad_id: adId || null,
    ad_is_skippable: adIsSkippable,
    player_presentation: getPlayerPresentation(fullscreen)
  });
};

const sendPlayerAdCenter = (state: VideoPlayerStore): void => {
  const {
    fullscreen,
    mixpanelTalkDetails: { recommendation_id },
    ad
  } = state;

  const adId =
    ad?.adInfo?.creativeId && ad?.adInfo?.lineItemId
      ? `${ad?.adInfo?.creativeId}, ${ad?.adInfo?.lineItemId}`
      : '';
  const adIsSkippable = ad?.isSkippable;

  mixpanel.track('player_ad-center', {
    ...universalProperties(state),
    recommendation_id,
    ad_id: adId || null,
    ad_is_skippable: adIsSkippable,
    player_presentation: getPlayerPresentation(fullscreen)
  });
};

const sendPlayerSkipAd = (state: VideoPlayerStore): void => {
  const {
    fullscreen,
    mixpanelTalkDetails: { recommendation_id },
    ad
  } = state;

  const adId =
    ad?.adInfo?.creativeId && ad?.adInfo?.lineItemId
      ? `${ad?.adInfo?.creativeId}, ${ad?.adInfo?.lineItemId}`
      : '';
  const adIsSkippable = ad?.isSkippable;

  mixpanel.track('player_skip-ad', {
    ...universalProperties(state),
    recommendation_id,
    ad_id: adId || null,
    ad_is_skippable: adIsSkippable,
    player_presentation: getPlayerPresentation(fullscreen)
  });
};

const sendPlayerPreRollStart = (state: VideoPlayerStore): void => {
  const {
    fullscreen,
    mixpanelTalkDetails: { recommendation_id },
    ad
  } = state;

  const adId =
    ad?.adInfo?.creativeId && ad?.adInfo?.lineItemId
      ? `${ad?.adInfo?.creativeId}, ${ad?.adInfo?.lineItemId}`
      : '';
  const adIsSkippable = ad?.isSkippable;

  mixpanel.track('player_pre_roll_start', {
    ...universalProperties(state),
    recommendation_id,
    ad_id: adId || null,
    ad_is_skippable: adIsSkippable,
    player_presentation: getPlayerPresentation(fullscreen)
  });
};

const sendPlayerPreRollComplete = (state: VideoPlayerStore): void => {
  const {
    fullscreen,
    mixpanelTalkDetails: { recommendation_id },
    ad
  } = state;

  const adId =
    ad?.adInfo?.creativeId && ad?.adInfo?.lineItemId
      ? `${ad?.adInfo?.creativeId}, ${ad?.adInfo?.lineItemId}`
      : '';
  const adIsSkippable = ad?.isSkippable;

  mixpanel.track('player_pre_roll_complete', {
    ...universalProperties(state),
    recommendation_id,
    ad_id: adId || null,
    ad_is_skippable: adIsSkippable,
    player_presentation: getPlayerPresentation(fullscreen)
  });
};

const sendPlayerPostRollStart = (state: VideoPlayerStore): void => {
  const {
    fullscreen,
    mixpanelTalkDetails: { recommendation_id },
    ad
  } = state;

  const adId =
    ad?.adInfo?.creativeId && ad?.adInfo?.lineItemId
      ? `${ad?.adInfo?.creativeId}, ${ad?.adInfo?.lineItemId}`
      : '';
  const adIsSkippable = ad?.isSkippable;

  mixpanel.track('player_post_roll_start', {
    ...universalProperties(state),
    recommendation_id,
    ad_id: adId || null,
    ad_is_skippable: adIsSkippable,
    player_presentation: getPlayerPresentation(fullscreen)
  });
};

const sendPlayerPostRollComplete = (state: VideoPlayerStore): void => {
  const {
    fullscreen,
    mixpanelTalkDetails: { recommendation_id },
    ad
  } = state;

  const adId =
    ad?.adInfo?.creativeId && ad?.adInfo?.lineItemId
      ? `${ad?.adInfo?.creativeId}, ${ad?.adInfo?.lineItemId}`
      : '';
  const adIsSkippable = ad?.isSkippable;

  mixpanel.track('player_post_roll_complete', {
    ...universalProperties(state),
    recommendation_id,
    ad_id: adId || null,
    ad_is_skippable: adIsSkippable,
    player_presentation: getPlayerPresentation(fullscreen)
  });
};

const sendPlayerComplete = (state: VideoPlayerStore): void => {
  const {
    fullscreen,
    mixpanelTalkDetails: { recommendation_id }
  } = state;

  mixpanel.track('player_complete', {
    ...universalProperties(state),
    recommendation_id,
    player_presentation: getPlayerPresentation(fullscreen)
  });
};

const sendPlayerProgressTime = (
  progress: number,
  state: VideoPlayerStore
): void => {
  const {
    fullscreen,
    mixpanelTalkDetails: { recommendation_id }
  } = state;

  trackVWOCustomConversionById(goalMarks[progress]);

  mixpanel.track(`player_${progress}_progress_time`, {
    ...universalProperties(state),
    recommendation_id,
    player_presentation: getPlayerPresentation(fullscreen)
  });
};

const sendPlayerPaused = (state: VideoPlayerStore): void => {
  const {
    fullscreen,
    roundedTime: action_time,
    mixpanelTalkDetails: { media_type, recommendation_id },
    contentState,
    userInitiated
  } = state;

  if (!userInitiated) return;

  mixpanel.track('player_paused', {
    ...universalProperties(state),
    action_time,
    recommendation_id,
    media_type,
    content_state: contentState,
    player_presentation: getPlayerPresentation(fullscreen)
  });
};

const sendTalkReadTranscript = (state: VideoPlayerStore): void => {
  const {
    roundedTime: action_time,
    transcriptLanguage,
    mixpanelTalkDetails: { transcript_language }
  } = state;

  mixpanel.track('talk_read_transcript', {
    ...universalProperties(state),
    action_time,
    transcript_language: transcriptLanguage || transcript_language
  });
};

const sendTalkClickTranscript = (state: VideoPlayerStore): void => {
  const {
    transcriptLanguage,
    roundedTime: action_time,
    mixpanelTalkDetails: { transcript_language }
  } = state;

  mixpanel.track('talk_transcript_click', {
    ...universalProperties(state),
    action_time,
    transcript_language: transcriptLanguage || transcript_language
  });
};

const sendCloseTranscript = (state: VideoPlayerStore): void => {
  const {
    transcriptLanguage,
    roundedTime: action_time,
    mixpanelTalkDetails: { transcript_language }
  } = state;

  mixpanel.track('close_transcript', {
    ...universalProperties(state),
    action_time,
    transcript_language: transcriptLanguage || transcript_language
  });
};

const sendTalkAddToList = (state: VideoPlayerStore): void => {
  const {
    roundedTime: action_time,
    mixpanelTalkDetails: { media_type }
  } = state;

  mixpanel.track('talk_add_to_list', {
    ...universalProperties(state),
    action_time,
    media_type,
    signed_in: getLoggedIn()
  });
};

const sendTalkAddToLike = (state: VideoPlayerStore): void => {
  const {
    roundedTime: action_time,
    mixpanelTalkDetails: { media_type, recommendation_id }
  } = state;

  mixpanel.track('talk_add_to_like', {
    ...universalProperties(state),
    action_time,
    media_type,
    recommendation_id,
    signed_in: getLoggedIn()
  });
};

const sendTalkTakeawayEntry = (state: VideoPlayerStore): void => {
  const {
    roundedTime: action_time,
    mixpanelTalkDetails: { media_type, recommendation_id }
  } = state;

  mixpanel.track('talk_takeaways_click', {
    ...universalProperties(state),
    action_time,
    media_type,
    recommendation_id,
    signed_in: getLoggedIn()
  });
};

const sendTalkComment = (state: VideoPlayerStore): void => {
  const {
    roundedTime: action_time,
    mixpanelTalkDetails: { media_type }
  } = state;

  mixpanel.track('talk_comment', {
    ...universalProperties(state),
    action_time,
    media_type,
    signed_in: getLoggedIn()
  });
};

const sendPlayerEnterFullScreen = (state: VideoPlayerStore): void => {
  const {
    fullscreen,
    roundedTime: action_time,
    mixpanelTalkDetails: { recommendation_id, media_type }
  } = state;

  mixpanel.track('player_enter_full_screen', {
    ...universalProperties(state),
    recommendation_id,
    media_type,
    action_time,
    player_presentation: getPlayerPresentation(fullscreen)
  });
};

const sendPlayerExitFullScreen = (state: VideoPlayerStore): void => {
  const {
    fullscreen,
    roundedTime: action_time,
    mixpanelTalkDetails: { recommendation_id, media_type }
  } = state;

  mixpanel.track('player_exit_full_screen', {
    ...universalProperties(state),
    recommendation_id,
    media_type,
    action_time,
    player_presentation: getPlayerPresentation(fullscreen)
  });
};

const sendSetSubtitleLanguage = (state: VideoPlayerStore): void => {
  const {
    fullscreen,
    roundedTime: action_time,
    mixpanelTalkDetails: {
      recommendation_id,
      media_type,
      language_parameter_value
    }
  } = state;

  mixpanel.track('player_set_subtitle_language', {
    ...universalProperties(state),
    related_tags: '',
    recommendation_id,
    player_presentation: getPlayerPresentation(fullscreen),
    media_type,
    language_parameter_value,
    action_time
  });
};

const sendTalkShareStart = (
  state: VideoPlayerStore,
  sharing_service: string
): void => {
  const {
    formattedTime: action_time,
    fullscreen,
    mixpanelTalkDetails: {
      media_type,
      recommendation_id,
      talk_page_view_context,
      language_parameter_value
    }
  } = state;

  mixpanel.track('talk_share_start', {
    ...universalProperties(state),
    action_time,
    recommendation_id,
    media_type,
    talk_page_view_context,
    sharing_service,
    language_parameter_value,
    player_presentation: getPlayerPresentation(fullscreen)
  });
};

const sendPlayerSetPlaybackSpeed = (
  state: VideoPlayerStore,
  payload: number
): void => {
  const {
    fullscreen,
    roundedTime: action_time,
    mixpanelTalkDetails: { media_type },
    userInitiated
  } = state;

  if (!userInitiated) return;

  mixpanel.track('player_set_playback_speed', {
    ...universalProperties(state, { playbackRate: payload }),
    action_time,
    media_type,
    player_presentation: getPlayerPresentation(fullscreen)
  });
};

export {
  sendPlayerStart,
  sendToggledAutoplay,
  sendTalkDownloaded,
  sendPlayerPreRollStart,
  sendPlayerPreRollComplete,
  sendPlayerPostRollStart,
  sendPlayerPostRollComplete,
  sendPlayerComplete,
  sendPlayerProgressTime,
  sendPlayerEnterFullScreen,
  sendPlayerExitFullScreen,
  sendPlayerPaused,
  sendTalkReadTranscript,
  sendTalkClickTranscript,
  sendTalkAddToList,
  sendTalkShareStart,
  sendTalkAddToLike,
  sendTalkComment,
  sendSetSubtitleLanguage,
  sendPlayerSetPlaybackSpeed,
  sendCloseTranscript,
  sendTalkMuteToggle,
  sendTalkVolumeChange,
  sendPlayerLearnMore,
  sendPlayerAdCenter,
  sendPlayerSkipAd,
  sendTalkTakeawayEntry
};
