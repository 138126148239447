import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

/**
 * Return the canonical URL for the page
 */
export function useCanonicalUrl(): string {
  const { locale } = useIntl();
  const { asPath } = useRouter();
  /* Remove the query string from the path */
  const path = asPath.replace(/\?.*$/, '');

  const baseUrl = `https://www.ted.com${path}`;
  return useMemo(
    () =>
      locale && locale !== 'en' ? `${baseUrl}?language=${locale}` : baseUrl,
    [baseUrl, locale]
  );
}
