import HttpStatusCodes from 'http-status-codes';
import Head from 'next/head';
import React from 'react';

import Markdown from 'components/markdown';
import { client } from 'f';
import errors from './errors';

type HttpStatusCodesMap = typeof HttpStatusCodes;
type HttpStatusCode = Extract<
  HttpStatusCodesMap[keyof HttpStatusCodesMap],
  number
>;

type ErrorPageProps = {
  statusCode: HttpStatusCode;
  error?: Error;
};

const styles = `
  * {
    box-sizing: border-box;
  }
  html,
  body {
    background: white;
    color: #444;
    margin: 0;
    padding: 0;
    height: 100%;
  }
  html,
  input {
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: 300;
    line-height: 1.4;
  }
  body {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAsIDAsIDEwMCwgMTAwIiB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCI+PHRleHQgeD0iNTAiIHk9IjUwIiBmaWxsPSJyZ2JhKDAsIDAsIDAsIDAuMDA4KSIgZm9udC1mYW1pbHk9InNhbnMtc2VyaWYiIGZvbnQtc2l6ZT0iMjUiIGZvbnQtd2VpZ2h0PSI3MDAiIHRleHQtYW5jaG9yPSJtaWRkbGUiIHRyYW5zZm9ybT0icm90YXRlKC00NSA1MCw1MCkiPjQwNDwvdGV4dD48L3N2Zz4=);
  }
  a {
    color: #555;
    text-decoration: none;
    transition: color 0.3s;
    position: relative;
  }
  a:after {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    content: ' ';
    transition: border-color 0.4s;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  a:hover {
    color: #222;
  }
  a:hover:after {
    border-color: rgba(0, 0, 0, 0.4);
  }
  h1 {
    font-size: 22.4px;
    font-size: 1.4rem;
    font-weight: 400;
    margin: 12.8px 0;
    margin: 0.8rem 0;
  }
  h1 a:after {
    display: none;
  }
  h2 {
    border-top: 1px solid #e62b1e;
    font-size: 19.2px;
    font-size: 1.2rem;
    font-weight: 400;
    margin: 22.4px 0 9.6px;
    margin: 1.4rem 0 0.6rem;
    padding-top: 22.4px;
    padding-top: 1.4rem;
  }
  p {
    margin: 9.6px 0;
    margin: 0.6rem 0;
  }
  ul {
    font-size: 14.4px;
    font-size: 0.9rem;
    list-style-type: circle;
    margin: 0;
    padding: 0;
  }
  li {
    color: rgba(0, 0, 0, 0.2);
    margin: 4.8px 0;
    margin: 0.3rem 0;
  }
  #__next {
    height: 100%;
  }
  .t {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .t__c {
    display: table-cell;
    vertical-align: middle;
  }
  .Error {
    background: #fafafa;
    font-size: 90%;
    padding: 12.8px;
    padding: 0.8rem;
    position: relative;
    margin: 12.8px -12.8px;
    margin: 0.8rem -0.8rem;
  }
  .Error,
  .Error a {
    color: #777;
    text-decoration: none;
  }
  .Error a:after {
    display: none;
  }
  .Notice {
    margin: 0 auto;
    padding: 0 32px 16px;
    padding: 0 2rem 1rem;
    width: 100%;
    max-width: 544px;
    max-width: 34rem;
  }
  .Search {
    margin: 12.8px 0;
    margin: 0.8rem 0;
    position: relative;
    width: 100%;
    max-width: 320px;
    max-width: 20rem;
  }
  .Search__input {
    background: #fff;
    border: 1px solid #d4d4d4;
    border-radius: 4px;
    font-size: 16px;
    font-size: 1rem;
    padding: 7px 29px 7px 8px;
    width: 100%;
  }
  .Search__button {
    background-color: transparent;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAMFBMVEVMaXFmZmZmZmZfX19mZmZmZmZlZWVlZWVlZWVmZmZmZmZnZ2dlZWVlZWVmZmZmZmYuzR/OAAAAD3RSTlMAqUYEMXSb01+JHBDq+b3CbPMEAAABN0lEQVR42u2U3XKFIAyEQ0gEBfX937akzCl1qCunzrQ37p2Ez/xslB49+gelOIW9KDuVN1Fx+zeFSONaXmjDh7NzBVY3q6rL9Wn2Q6zuJsdfdcz1YITWvsw02dl2TXOrsTu9nNVabil1EqOZsFzL0OfOCftr/frTWehl4hNLfS7uoZkls+QsGHHXOO5LcAKw+elRTxnAoawCXh8w7xVuAp9P04TdEDCR2/CdsoPZDI1cCJqBjUTfMiwsQyNpsaZRy5GAAtiDrcbwQCcYukrNp7+YBcOy/zyzZG/dhn6e3OU1tpUNvC5Sf+zXah6h/SedY8PZ5jxKz/Weiywicc4NHamc7f5RYZxOuh7TLd6N0+Sje/Gb2l71NFYSZql7caQjDaunA92gV7pBb3SDFvqdNO+b0KNHf6UP9pkYxiqw5x4AAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    background-size: 30px 30px;
    border: none;
    overflow: hidden;
    text-indent: -9999em;
    margin-top: -15px;
    padding: 0;
    position: absolute;
    top: 50%;
    right: 2px;
    width: 30px;
    height: 30px;
  }
`;

export default function ErrorPage({
  statusCode,
  error
}: ErrorPageProps): React.ReactElement {
  const {
    title,
    headline,
    message,
    hasSearch,
    image: Image
  } = errors(statusCode);

  return (
    <>
      <Head>
        <title>{`TED: ${title}`}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <style type="text/css">{styles}</style>
      </Head>
      <div className="t">
        <div className="t__c">
          <div className="Notice">
            {Image && <Image />}
            <h1>{headline}</h1>
            <Markdown>{message}</Markdown>

            {hasSearch && (
              <form
                className="Search"
                action="https://www.ted.com/search"
                role="search"
              >
                <input
                  className="Search__input"
                  placeholder="Search TED.com…"
                  name="q"
                />
                <input className="Search__button" type="submit" value="Go" />
              </form>
            )}

            {(statusCode || error) && (
              <div className="Error">
                <p data-testid="error-message">
                  We apologize for the inconvenience. Our team has been notified
                  and is working on a fix.
                </p>
                {process.env.NODE_ENV !== 'production' && (
                  <div>
                    <h3>Developer Details:</h3>
                    {statusCode && <p>Status Code: {statusCode}</p>}
                    {error && (
                      <>
                        <p>Error Message: {error.message}</p>
                      </>
                    )}
                  </div>
                )}
              </div>
            )}

            <h2>What&lsquo;s next?</h2>
            <ul>
              {client &&
                window.history.length > 1 &&
                document.referrer &&
                /^https?:\/\//.test(document.referrer) && (
                  <li>
                    <a
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        window.history.back();
                      }}
                      role="button"
                    >
                      Go to the previous page
                    </a>
                  </li>
                )}
              <li>
                <a href="https://www.ted.com">Go to the TED.com homepage</a>
              </li>
              <li>
                <a href="https://support.ted.com">Visit our Help Desk</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
