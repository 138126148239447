import { md5 } from 'js-md5';

// YYYY-MM-DD
export const getOptinDate = (): string => {
  const today = new Date();

  const year = today.toLocaleString('default', { year: 'numeric' });
  const month = today.toLocaleString('default', { month: '2-digit' });
  const day = today.toLocaleString('default', { day: '2-digit' });

  return `${year}-${month}-${day}`;
};

export const generateSig = (jsonParams: string): string => {
  const md5Hash = md5(
    `${process.env.NEXT_PUBLIC_SAILTHRU_API_SECRET}${process.env.NEXT_PUBLIC_SAILTHRU_API_KEY}json${jsonParams}`
  );
  return md5Hash;
};
