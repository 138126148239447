import 'components/@tedui/assets/css/icons.css';
import 'styles/global.css';
import 'swiper/swiper-bundle.min.css';

import { ApolloProvider } from '@apollo/client';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { parseCookies } from 'nookies';
import type { JSX, ReactNode } from 'react';
import React, { useEffect } from 'react';
import type { IntlConfig } from 'react-intl';
import { IntlProvider } from 'react-intl';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useLastTouchUtmParams } from 'lib/analytics/mixpanel-helpers';
import { getCurrentUserId, getLoggedIn } from 'lib/auth';
import { client, noop } from 'lib/f';
import { initializeApollo } from 'lib/graphql/client';
import { useDidMount } from 'lib/hooks/useDidMount';
import { usePageLoader } from 'lib/hooks/usePageLoader';
import { usePolyfills } from 'lib/hooks/usePolyfills';
import { useTrackPageViews } from 'lib/hooks/useTrackPageViews';
import type { VideoData } from 'lib/pages/talks/slug/[...slug].props';
import VHProvider from 'lib/vh-provider';
import {
  FEATURE_FLAGS,
  featureFlagsConfig,
  getVWOFeatureToggle,
  useFeatureFlags,
  useVWOCookies,
  type FeatureFlagsState
} from 'lib/vwo';

import { GPTScript } from 'components/ads/gpt-script';
import { PrebidScript } from 'components/ads/prebid-script';
import CanonicalTags from 'components/canonical-tags';
import ErrorBoundary from 'components/error';
import PrismicPreview from 'components/PRISMIC/PrismicPreview';
import { SailthruCookieProvider } from 'components/SailthruCookie/SailthruCookieContext';
import { TooltipProvider } from 'components/shared/Tooltip';
import { DarkModeProvider } from 'components/theme/DarkModeProvider';
import { Toaster } from 'sonner';

const FundraiseUp = dynamic(() => import('components/FundraiseUp'), {
  ssr: false
});
const UpdateBrowserBanner = dynamic(
  () => import('components/UpdateBrowserBanner')
);

type NextPageWithLayout = NextPage<{ videoData: VideoData }> & {
  getLayout?: (page: JSX.Element) => ReactNode;
};

type TEDAppProps = AppProps<{ videoData: VideoData }> & {
  Component: NextPageWithLayout;
  messages: IntlConfig['messages'];
  language: string;
  responseCode: string;
  featureFlags: Record<string, boolean>;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000, // 1 minute
      gcTime: 5 * 60 * 1000, // 5 minutes
      retry: 1
    }
  }
});

const PrismicProvider = ({ children }: { children: React.ReactNode }) => {
  return <PrismicPreview>{children}</PrismicPreview>;
};

export const TEDApp = ({
  Component,
  pageProps,
  messages,
  language
}: TEDAppProps): JSX.Element => {
  const router = useRouter();
  const isEmbed = router.asPath.includes('embed');
  const { updateFlag } = useFeatureFlags();

  useLastTouchUtmParams();
  usePolyfills();
  usePageLoader();
  useVWOCookies();
  const getLayout = Component.getLayout ?? (page => page);
  const hasMounted = useDidMount();
  useTrackPageViews(pageProps.videoData, hasMounted);

  useEffect(() => {
    if (!client || isEmbed) return;

    let isMounted = true;
    const cookies = parseCookies();
    const vwoUserId = getLoggedIn()
      ? getCurrentUserId()
      : cookies[FEATURE_FLAGS.VWO_UUID_COOKIE_NAME];

    const fetchFlags = async () => {
      const entries = Object.entries(featureFlagsConfig);
      await Promise.all(
        entries.map(async ([flag, config]) => {
          try {
            const featureFlag = await getVWOFeatureToggle({
              campaignKey: config.campaignKey as string,
              sdkKey: config.sdkKey,
              userId: vwoUserId
            });
            if (isMounted) {
              updateFlag(flag as keyof FeatureFlagsState, featureFlag);
            }
          } catch {
            if (isMounted) {
              updateFlag(flag as keyof FeatureFlagsState, false);
            }
          }
        })
      );
    };

    fetchFlags();
    return () => {
      isMounted = false;
    };
  }, [updateFlag, client, isEmbed]);

  return (
    <IntlProvider
      messages={messages}
      locale={language}
      defaultLocale="en"
      onError={noop}
    >
      <DarkModeProvider>
        <VHProvider>
          <ApolloProvider client={initializeApollo()}>
            <QueryClientProvider client={queryClient}>
              <SailthruCookieProvider>
                <TooltipProvider>
                  <ErrorBoundary>
                    <CanonicalTags />

                    {!isEmbed &&
                      !router.pathname.match(/^\/(?:404|500|_error)$/) &&
                      !router.pathname.startsWith('/_') && (
                        <>
                          <GPTScript />
                          <FundraiseUp />
                          <PrebidScript />
                          <div id="ad-funbzaaeva" />
                        </>
                      )}
                    {hasMounted && <UpdateBrowserBanner />}
                    {isEmbed ? (
                      getLayout(<Component {...pageProps} />)
                    ) : (
                      <PrismicProvider>
                        {getLayout(<Component {...pageProps} />)}
                        <Toaster
                          position="bottom-left"
                          richColors
                          expand
                          closeButton
                          toastOptions={{
                            classNames: {
                              toast:
                                'flex items-start justify-between rounded-md p-4'
                            }
                          }}
                        />
                      </PrismicProvider>
                    )}
                  </ErrorBoundary>
                </TooltipProvider>
              </SailthruCookieProvider>
            </QueryClientProvider>
          </ApolloProvider>
        </VHProvider>
      </DarkModeProvider>
    </IntlProvider>
  );
};

export default TEDApp;
