import { client } from 'f';
import { sendErrorToServices } from 'lib/logging';
import Script from 'next/script';
import { useEffect } from 'react';

const SCRIPT_ID = 'yourbow-prebid-script';
const SCRIPT_SRC = 'https://cdn.yourbow.com/ted/hbyb.js';

// Initialize prebid queue
function initializePrebid(): void {
  if (!window.pbjs) {
    window.pbjs = { que: [] };
  }
}

export function PrebidScript() {
  useEffect(() => {
    initializePrebid();
  }, []);

  return (
    <Script
      async
      id={SCRIPT_ID}
      src={SCRIPT_SRC}
      strategy="lazyOnload"
      onLoad={() => {
        window.yourBowScriptLoaded = true;
        window?.runAds?.();
      }}
      onError={e => {
        sendErrorToServices('Error loading Prebid script:', e);
      }}
    />
  );
}

export function refreshVideoAds(): void {
  if (!client) return;

  window.pbjs?.que.push(() => {
    window.runVideoAds();
  });
}

// Helper to check if the script is loaded and ready
export function isPrebidReady(): boolean {
  return window.yourBowScriptLoaded === true && window.pbjs?.que !== undefined;
}
