import ErrorPage from 'components/error-page';
import { sendErrorToServices } from 'lib/logging';
import React, {
  type ErrorInfo,
  type PropsWithChildren,
  type ReactNode
} from 'react';

export function createMessage(label: string) {
  return (level: string) =>
    (message: string): string => {
      return `[${level.toUpperCase()}] Zenith(${label}): ${message}`;
    };
}

interface ErrorBoundaryState {
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

interface ErrorBoundaryProps {
  fallback?: React.ReactNode | ((state: ErrorBoundaryState) => ReactNode);
}

export default class ErrorBoundary extends React.Component<
  PropsWithChildren<ErrorBoundaryProps>,
  ErrorBoundaryState
> {
  constructor(props: PropsWithChildren<ErrorBoundaryProps>) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ error, errorInfo });
    sendErrorToServices('error caught during react render', error);
  }

  static getDerivedStateFromError(error: Error): {
    error: Error;
  } {
    return { error };
  }

  render(): React.ReactNode {
    const { children, fallback } = this.props;
    const { error, errorInfo } = this.state;
    if (error) {
      if (fallback) {
        const fallbackContent =
          typeof fallback === 'function'
            ? fallback({ error, errorInfo })
            : fallback;
        return fallbackContent;
      }
      return <ErrorPage statusCode={500} error={error} />;
    }
    return children;
  }
}

// @ts-ignore this is stupid.
ErrorBoundary.displayName = 'Zenith(ErrorBoundary)';
