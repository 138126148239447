// @ts-strict-ignore
import { useEffect } from 'react';

import { addNoTouchClass } from 'lib/util';

export function usePolyfills(): void {
  useEffect(() => {
    addNoTouchClass();
  }, []);
}
