type TEDProps = {
  signInUrl: string;
  signOutUrl: string;
  zone: null | string;
};

const TED: TEDProps = {
  signInUrl: 'https://auth.ted.com/session/new',
  signOutUrl:
    'https://auth.ted.com/session/logout?referrer=https://www.ted.com/session/logout',
  zone: null
};

const tedFunction = (key: keyof TEDProps): string | null => TED[key];

export default tedFunction;
