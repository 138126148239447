import { marked } from 'marked';
import { useMemo } from 'react';

import classNames from 'classnames';
import { compose, defaultTo, replace } from 'f';
import { editorialize } from 'lib/business';

// Configure marked for synchronous usage
marked.setOptions({
  async: false
});

type MarkdownProps = {
  className?: string;
  children: string;
};

export default function Markdown({
  className,
  children,
  ...props
}: MarkdownProps): React.ReactNode {
  const markdown = useMemo(() => {
    const processed = compose(
      defaultTo(''),
      replace(/\r\n/g, '<br />'),
      editorialize
    )(children);
    return marked.parse(processed);
  }, [children]);

  return (
    <span
      className={classNames('markdown-content', className)}
      dangerouslySetInnerHTML={{ __html: markdown }}
      {...props}
    />
  );
}
