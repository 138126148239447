import { NextRouter, useRouter } from 'next/router';
import { useCallback, useEffect, useRef } from 'react';

import useVideoPlayerStore from 'components/video-player/store';
import mixpanel from 'lib/analytics/mixpanel';
import { generateTalkTopicsList } from 'lib/analytics/mixpanel-helpers';
import { sendBeacon } from 'lib/comscore';
import { VideoData } from 'lib/pages/talks/slug/[...slug].props';
import { debounce } from 'lodash-es';
import { parseCookies } from 'nookies';

function sendComscorePageBeacon() {
  sendBeacon({
    c1: '2',
    c2: process.env.NEXT_PUBLIC_COMSCORE_CLIENT_ID
  });
}

const getPlaylistReferrer = (referrer: string) => {
  if (referrer && referrer.includes('playlist-')) {
    return referrer.split('playlist-')[1];
  }
  return null;
};

const getContentDiscoveryContextType = (pathName: string) => {
  if (typeof window !== 'undefined') {
    const contextType = pathName.split('/')[1];

    if (contextType === 'dashboard') {
      const subpage = pathName.split('/')[2];

      if (subpage === 'my-library') {
        const myLibrarySubpage = pathName.split('/')[3];
        return `my-library${myLibrarySubpage ? `-${myLibrarySubpage}` : ``}`;
      }

      return contextType;
    }

    return contextType;
  }

  return null;
};

export const consumeLocalStorage = (key: string) => {
  if (typeof window !== 'undefined') {
    const storageValue = window.localStorage.getItem(key);
    return storageValue || null;
  }

  return null;
};

export function generateMixpanelEvent(
  router: NextRouter
): Record<string, string | string[] | number | unknown> {
  const cookies = parseCookies();

  return {
    language_parameter_value: router?.query?.language || 'en',
    system_language: navigator?.language || null,
    navigation_context: consumeLocalStorage('navigation_context'),
    content_discovery_context:
      consumeLocalStorage('content_discovery_context') || null,
    content_discovery_page_type: getContentDiscoveryContextType(
      router?.pathname
    ),
    cta_impression_context: consumeLocalStorage('cta_impression_context'),
    sailthru_hid_set: document.cookie.includes('sailthru_hid'),
    sailthru_hid: cookies.sailthru_hid || null
  };
}

export function useTrackPageViews(
  videoData: VideoData,
  hasMounted: boolean
): void {
  const router = useRouter();
  const lastTrackedPathRef = useRef('');

  const videoDataRef = useRef<VideoData>(undefined);
  const routerRef = useRef<NextRouter>(undefined);

  const subtitleLanguage = useVideoPlayerStore(state => state.subtitle);
  const autoplayed = useVideoPlayerStore(state => state.autoplayed);

  routerRef.current = router;
  videoDataRef.current = videoData;

  const isTalkIndexPage = useCallback((fullPath: string) => {
    const path = fullPath.split('?')[0]; // Remove query string
    return ['/talks'].includes(path);
  }, []);

  const isTalksPage = useCallback((fullPath: string) => {
    const path = fullPath.split('?')[0]; // Remove query string
    const isTalksChild =
      path.startsWith('/talks/') &&
      !['/talks/', '/talks/*/transcript'].includes(path);
    const isArticles = path.startsWith('/articles/');
    return isTalksChild || isArticles;
  }, []);

  const trackPageView = useCallback(
    (path: string) => {
      if (!hasMounted || path.includes('/transcript')) {
        return;
      }

      const currentPathWithoutQuery = path.split('?')[0];

      if (lastTrackedPathRef.current === currentPathWithoutQuery) {
        return;
      }

      lastTrackedPathRef.current = currentPathWithoutQuery;

      sendComscorePageBeacon();

      const videoDataObject = videoDataRef.current;

      const getTalkPageContext = () => {
        const contexts = ['Talk'];
        if (path.includes('/transcript')) {
          contexts.push('Transcript');
        }
        if (path.includes('referrer=playlist')) {
          contexts.push('Playlist');
        }
        if (path.includes('rid=')) {
          contexts.push('Recommendation');
        }
        return contexts.join('-'); // Talks-Playlist-Transcript
      };

      let mixpanelEvent = generateMixpanelEvent(routerRef.current);

      if (isTalkIndexPage(path)) {
        return;
      }

      if (path.includes('/playlists/') && routerRef.current?.query?.id) {
        mixpanelEvent = {
          ...mixpanelEvent,
          playlist_id: routerRef.current.query.id
        };
      }

      if (isTalksPage(path)) {
        mixpanelEvent = {
          ...mixpanelEvent,
          autoplay_state: `${autoplayed}`,
          talk_id: videoDataObject?.id ? String(videoDataObject?.id) : null,
          talk_type_id: videoDataObject?.type?.id || null,
          talk_type_name: videoDataObject?.type?.name || null,
          talk_page_view_context: getTalkPageContext() || null,
          talk_slug: routerRef.current?.query?.slug
            ? routerRef.current.query.slug[0]
            : null,
          talk_language: videoDataObject?.audioInternalLanguageCode || null,
          recommendation_id:
            (routerRef.current?.query && routerRef.current?.query.rid) || null,
          playlist_slug: getPlaylistReferrer(
            routerRef?.current?.query?.referrer as string
          ),
          video_duration: videoDataObject?.duration,
          video_topics: generateTalkTopicsList(videoDataObject?.topics),
          video_post_date: videoDataObject?.publishedAt,
          talkstar_context: videoDataObject?.videoContext,
          subtitle_language: subtitleLanguage
        };
      }

      mixpanel.track('screen_view', mixpanelEvent);
      // Add Track_pageview that is built for SPAs to feed in custom Events
      // Will Likely replace screen_view
      mixpanel.track_pageview(mixpanelEvent);
    },
    [hasMounted, isTalkIndexPage, isTalksPage, autoplayed, subtitleLanguage]
  );

  useEffect(() => {
    const debouncedTrackPageView = debounce(trackPageView, 1000);

    const handleRouteChangeComplete = (url: string) => {
      debouncedTrackPageView(url);
    };

    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    // Initial page load tracking
    if (hasMounted) {
      debouncedTrackPageView(router.asPath);
    }

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      debouncedTrackPageView.cancel();
    };
  }, [trackPageView, router, hasMounted]);
}
