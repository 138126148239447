import vwoSDK from 'vwo-node-sdk';

import type { VWOFeatureToggle } from './client.props';

export const getVWOFeatureToggle = async ({
  campaignKey,
  sdkKey,
  userId,
  ssrUrl
}: VWOFeatureToggle): Promise<boolean> => {
  const url = ssrUrl || `/api/vwo?sdkKey=${sdkKey}`;
  const response = await fetch(url);

  if (!response.ok) {
    throw new Error(
      `Error occurred while fetching feature toggle flag: ${response.statusText}`
    );
  }

  const settingsFile = await response.json();

  try {
    const vwoClientInstance = vwoSDK.launch({
      settingsFile
    });

    return vwoClientInstance.isFeatureEnabled(campaignKey, userId);
  } catch (e) {
    console.error('VWO Error:', e);
  }

  return false;
};
