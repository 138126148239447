import mixpanel from 'mixpanel-browser';
import { parseCookies, setCookie } from 'nookies';

import { MIXPANEL_PRODUCT } from 'lib/analytics/constants';
import { IS_PROD, MIXPANEL_API_HOSTS } from 'lib/constants';
import { sendErrorToServices } from 'lib/logging';

const MIXPANEL_KEY = process.env.NEXT_PUBLIC_MIXPANEL_KEY;
// Cookie being set for TED Auth to read, since we are using localStorage
// persistence with the mixpanel library.
const MIXPANEL_UUID_COOKIE = 'mp_uuid';
const COOKIE_OPTIONS = {
  // Sets cookie for .ted.com and all subdomains
  domain: '.ted.com',
  // Only send cookie over HTTPS in production
  secure: IS_PROD,
  // Allow cookie to be sent in cross-site requests but only for top-level navigation
  sameSite: 'lax',
  // Cookie expires after 1 year (31536000 seconds)
  maxAge: 31536000,
  // Cookie is available across all paths on the domain
  path: '/'
} as const;

// Only initialize if we're in the browser
if (typeof window !== 'undefined') {
  if (!MIXPANEL_KEY) {
    console.error('Mixpanel API key is not defined');
  } else {
    try {
      mixpanel.init(MIXPANEL_KEY, {
        debug: !IS_PROD,
        api_host: IS_PROD
          ? MIXPANEL_API_HOSTS.PROD
          : MIXPANEL_API_HOSTS.STAGING,
        // This configuration is necessary to prevent mixpanel to create cookies and can be checked
        // here https://github.com/mixpanel/mixpanel-js/blob/master/doc/readme.io/javascript-full-api-reference.md#mixpanelset_config
        persistence: 'localStorage',
        // Add Mixpanel SPA Tracking Support (with checks for url, query string changes)
        // here https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#track-page-views
        track_pageview: 'url-with-path'
      });

      mixpanel.register({
        product: MIXPANEL_PRODUCT
      });
    } catch (error) {
      sendErrorToServices('Failed to initialize Mixpanel:', error);
    }

    try {
      // Check if mp_uuid cookie already exists
      const cookies = parseCookies();
      const existingUuid = cookies[MIXPANEL_UUID_COOKIE];
      const currentDistinctId = mixpanel.get_distinct_id();

      if (!existingUuid || existingUuid !== currentDistinctId) {
        setCookie(
          null,
          MIXPANEL_UUID_COOKIE,
          currentDistinctId,
          COOKIE_OPTIONS
        );
      }
    } catch (cookieError) {
      sendErrorToServices('Failed to set Mixpanel UUID cookie:', cookieError);
    }
  }
}

export default mixpanel;
