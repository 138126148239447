import { useRouter } from 'next/router';
import { useEffect } from 'react';

import type { Topic } from 'api';
import { capitalizeFirstLetter, client } from 'f';
import mixpanel from 'lib/analytics/mixpanel';
import { VideoData } from 'lib/pages/talks/slug/[...slug].props';

export const generateTalkTopicsList = (
  talkTopics: VideoData['topics']
): string[] | null =>
  talkTopics
    ? talkTopics.nodes
        .filter(
          (
            topic: Topic
          ): topic is {
            __typename?: 'Topic';
            id: string;
            name: string;
            slug?: string;
          } => typeof topic.name === 'string'
        )
        .map(({ name }) => capitalizeFirstLetter(name))
    : null;

export const getSystemLanguage = (): string | null => {
  return navigator?.language || null;
};

export const getTalkPageContext = (path: string): string => {
  const contexts = ['Talk'];
  if (path.includes('/transcript')) {
    contexts.push('Transcript');
  }
  if (path.includes('referrer=playlist')) {
    contexts.push('Playlist');
  }
  if (path.includes('rid=')) {
    contexts.push('Recommendation');
  }
  return contexts.join('-'); // Talks-Playlist-Transcript
};

export const getMixpanelDistinctId = (): string | null => {
  if (!client) return null;

  return mixpanel.get_distinct_id();
};

export const trackOutboundLinks = (
  linkId: string,
  linkContext: string,
  link: string,
  autoplayed?: boolean
): void => {
  if (!linkId || !linkContext || !link) {
    return;
  }

  mixpanel.track_links(linkId, 'outbound_link_click', {
    system_language: getSystemLanguage(),
    outbound_url: link,
    outbound_link_context: linkContext,
    autoplay_state: autoplayed || false
  });
};

const utmParamKeys = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term'
];

export const getLastTouchUtmParams = (
  queryString: string
): Record<string, string> | null => {
  const urlParams = new URLSearchParams(queryString);

  const activeUtmParamKeys = utmParamKeys.filter(key => urlParams.has(key));

  if (activeUtmParamKeys.length === 0) {
    return null;
  }

  return activeUtmParamKeys.reduce((utmParams, key) => {
    return { ...utmParams, [`${key} [last touch]`]: urlParams.get(key) };
  }, {});
};

export const getQueryStringWithoutUtmParams = (queryString: string): string => {
  const urlParams = new URLSearchParams(queryString);
  utmParamKeys.forEach(key => urlParams.delete(key));
  const searchString = urlParams.toString();

  if (searchString === '') {
    return '';
  }

  return `?${searchString}`;
};

export const useLastTouchUtmParams = (): void => {
  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    const utmParams = getLastTouchUtmParams(window.location.search);

    if (!utmParams) {
      return;
    }

    const cleanSearch = getQueryStringWithoutUtmParams(window.location.search);
    const cleanUrl = router.asPath.replace(window.location.search, cleanSearch);

    mixpanel.register(utmParams);

    router.replace(cleanUrl, undefined, { shallow: true });
  }, [router.isReady]);
};
