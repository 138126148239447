// @ts-strict-ignore
/*
  This file is based off ga-middleware.ts to provide a clean switch when GA is deprecated.
  The intention is this file will remain and match 1:1 to the implemented functionality
  for GA and ga-middleware.ts can simply be deleted.
*/
import { throttle } from 'lodash-es';

import { VideoPlayerStore } from 'components/video-player/store';

import type { PlayerMarks, TalkMarks } from './index';
import {
  sendPlayerPaused,
  sendPlayerPostRollComplete,
  sendPlayerPostRollStart,
  sendPlayerPreRollComplete,
  sendPlayerPreRollStart,
  sendPlayerProgressTime,
  sendPlayerSetPlaybackSpeed,
  sendPlayerStart,
  sendSetSubtitleLanguage,
  sendTalkAddToLike,
  sendTalkDownloaded,
  sendTalkReadTranscript
} from './index';
import {
  sendCloseTranscript,
  sendPlayerAdCenter,
  sendPlayerComplete,
  sendPlayerEnterFullScreen,
  sendPlayerExitFullScreen,
  sendPlayerLearnMore,
  sendPlayerSkipAd,
  sendTalkAddToList,
  sendTalkClickTranscript,
  sendTalkComment,
  sendTalkMuteToggle,
  sendTalkShareStart,
  sendTalkTakeawayEntry,
  sendTalkVolumeChange,
  sendToggledAutoplay
} from './mixpanel-events';

// Control whether this middleware is active.
// Normally set during hydration based on the media source:
// this middleware only applies to internally sourced media.
// Also set manually for unit tests.
let isActive = false;
export function setIsMixpanelAnalyticsActive(value: boolean): void {
  isActive = value;
}

let playerMarks: PlayerMarks = {
  hasPlayedSuccessfully: false,
  hasInitiallyRequested: false
};

let talkMarks: TalkMarks = {};

const getProgress = throttle(
  (currentTime, duration) => {
    if (!talkMarks.is95PercentComplete)
      return Math.floor((currentTime / duration) * 100);
  },
  1000,
  { trailing: false }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mixpanelMiddleware = () => (store: any) => (set, get, api) =>
  store(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (getPayload: any, name?: string) => {
      const payload = getPayload();
      if (name === 'onService')
        setIsMixpanelAnalyticsActive(payload?.service === 'internal');
      if (!isActive) return set(getPayload, name);
      const state: VideoPlayerStore = get();

      switch (name) {
        case 'requestPlay':
          sendPlayerStart(state);
          playerMarks.hasInitiallyRequested = true;
          break;

        case 'requestMute':
          sendTalkMuteToggle(state, payload);
          break;

        case 'requestVolume':
          sendTalkVolumeChange(state, payload);
          break;

        case 'onAddToList':
          sendTalkAddToList(state);
          break;

        case 'onAddComment':
          sendTalkComment(state);
          break;

        case 'onTakeawaysEntry':
          sendTalkTakeawayEntry(state);
          break;

        case 'autoPlay':
          sendToggledAutoplay(state, payload);
          break;

        case 'onDownload':
          sendTalkDownloaded(state, payload.downloadFormat);
          break;

        case 'onLike':
          sendTalkAddToLike(state);
          break;

        case 'onPlay':
          break;

        case 'onReady':
          // Reset talkMarks and playerMarks when player loads
          talkMarks = {
            isPlayerReady: payload.ready
          };
          playerMarks = {};
          break;

        case 'onPause': {
          const { currentTime, roundedDuration } = state;
          const isPauseTriggeredByVideoEnd = currentTime >= roundedDuration;

          if (!isPauseTriggeredByVideoEnd) {
            sendPlayerPaused(state);
          }

          break;
        }

        case 'onDuration':
          break;

        case 'onCurrentTime':
          {
            const { duration } = state;

            const progressMarks = [25, 50, 75, 95];
            const talkMarkKeys = [
              'is25PercentComplete',
              'is50PercentComplete',
              'is75PercentComplete',
              'is95PercentComplete'
            ];

            const progress = getProgress(payload.currentTime, duration);

            progressMarks.forEach((mark, index) => {
              const key = talkMarkKeys[index];
              if (progress === mark && !talkMarks[key]) {
                sendPlayerProgressTime(mark, state);
                talkMarks[key] = true;
              }
            });
          }

          break;

        case 'onTalk':
          // Reset talkMarks when talk loads
          {
            const { isPlayerReady } = talkMarks;

            if (isPlayerReady) {
              talkMarks = { isPlayerReady: true };
            } else {
              talkMarks = {};
            }
          }
          break;

        case 'onTedStart':
          break;

        case 'onTalkComplete':
          if (!talkMarks.hasCompleted) {
            sendPlayerComplete(state);
            talkMarks.hasCompleted = true;
          }
          break;

        case 'onTranscriptToggle':
          if (!talkMarks.hasFiredReadTranscript) {
            sendTalkReadTranscript(state);
            talkMarks.hasFiredReadTranscript = true;
          }
          break;

        case 'onTranscriptClick':
          sendTalkClickTranscript(state);
          break;

        case 'onCloseTranscript':
          sendCloseTranscript(state);
          break;

        case 'onSubtitle':
          break;

        case 'onSubtitleSet':
          sendSetSubtitleLanguage(state);
          break;

        case 'onPlaybackRate':
          if (talkMarks.isPlayerReady) {
            sendPlayerSetPlaybackSpeed(state, payload.playbackRate);
          }
          break;

        case 'onAdSkip':
          if (!talkMarks.hasSkippedAd) {
            sendPlayerSkipAd(state);
            talkMarks.hasSkippedAd = true;
          }
          break;

        case 'onAdClick':
          sendPlayerLearnMore(state);
          break;

        case 'onAdCenterClick':
          sendPlayerAdCenter(state);
          break;

        case 'onPrerollStart':
          if (!talkMarks.hasPrerollStarted) {
            sendPlayerPreRollStart(state);
            talkMarks.hasPrerollStarted = true;
          }
          break;

        case 'onPrerollComplete':
          if (!talkMarks.hasPrerollCompleted) {
            sendPlayerPreRollComplete(state);
            talkMarks.hasPrerollCompleted = true;
          }

          break;

        case 'onPostrollStart':
          if (!talkMarks.hasPostrollStarted) {
            sendPlayerPostRollStart(state);

            talkMarks.hasPostrollStarted = true;
          }
          break;

        case 'onPostrollComplete':
          if (!talkMarks.hasPostrollCompleted) {
            sendPlayerPostRollComplete(state);

            talkMarks.hasPostrollCompleted = true;
          }
          break;

        case 'onTalkShareStart':
          sendTalkShareStart(state, payload.talkShareDetails);
          break;

        case 'onError':
          break;

        case 'onFullscreen':
          if (payload.fullscreen) {
            sendPlayerEnterFullScreen(state);
          } else {
            sendPlayerExitFullScreen(state);
          }
          break;

        default:
          break;
      }

      return set(getPayload, name);
    },
    get,
    api
  );

export default mixpanelMiddleware;
